import React, { useRef, useState, useContext, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { addTasks } from "../../../store/tasks/slice";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { addTitles, getTitleDataAll } from "../../../store/titles/slice";
import { Image } from "primereact/image";
interface TitleProps {
  id: number;
  name: string;
}

export default function ModelSpendingTask({
  open,
  onClose,
}: {
  open: boolean;
  onClose: any;
}) {
  const dispatch = useDispatch();
  const {
    selectedWorkTaskId,
    toggleIsSpending,
    selectedTitleTask,
    toggleIsLoad,
    selectedStepsId,
    roleName,
  } = useContext(ProjectContext);
  const toast = useRef<Toast>(null);
  const [arrTitle, setArrTitle] = useState<TitleProps[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [newCategory, setNewCategory] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      fileUrl: string;
      fileName: string;
      fileSize: string;
      iconUrl: string;
      file: File;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => {
        const fileUrl = URL.createObjectURL(file);
        let iconUrl = "";

        // Determine icon based on file extension
        if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
          iconUrl = "/images/image/excel.png";
        } else if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
          iconUrl = "/images/image/word.png";
        } else if (
          file.name.endsWith(".jpg") ||
          file.name.endsWith(".jpeg") ||
          file.name.endsWith(".png") ||
          file.name.endsWith(".gif") ||
          file.name.endsWith(".bmp")
        ) {
          iconUrl = fileUrl; // Use the fileUrl itself as the icon for image files
        } else {
          iconUrl = "/images/image/default.png"; // Optional: Default icon for other file types
        }

        return {
          fileUrl,
          fileName: file.name,
          fileSize: `${(file.size / (1024 * 1024)).toFixed(2)}\u00A0MB`, // Use a non-breaking space
          iconUrl,
          file, // Save the file object itself
        };
      });
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleSave = () => {
    if (newCategory.trim()) {
      dispatch(
        addTitles({ workTaskId: selectedWorkTaskId, name: newCategory })
      );
    } else {
      // Hiển thị thông báo lỗi nếu không có nội dung
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Vui lòng nhập tên hạng mục",
        life: 3000,
      });
      return;
    }
  };

  useEffect(() => {
    if (arrTitle && insertTitle) {
      const newItem = arrTitle?.find((e: any) => e.name === newCategory);
      formik.setFieldValue("titleName", newItem);
      setNewCategory("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrTitle]);

  const footerContentTitle = (
    <div>
      {roleName && (
        <>
          <button
            className="custom-button"
            onClick={() => setVisible(false)}
            style={{ backgroundColor: "#808080", color: "#ffffff" }}
          >
            Huỷ
          </button>
          <button className="custom-button" onClick={() => handleSave()}>
            Lưu
          </button>
        </>
      )}
    </div>
  );

  const selectedTitleTemplate = (option: TitleProps, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const titleOptionTemplate = (option: TitleProps) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">{selectedTitleTask}</span>
    </div>
  );

  const footerContent = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {roleName === "pm" && (
        <>
          <div className="button-container">
            <input
              type="file"
              name="files"
              id="upload"
              hidden
              accept=".xlsx,.xls,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="upload" className="upload-file">
              <i className="pi pi-paperclip"></i> Đính kèm
            </label>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="custom-button"
              onClick={() => {
                onClose();
              }}
              style={{ backgroundColor: "#808080", color: "#ffffff" }}
            >
              Huỷ
            </button>
            <button
              className="custom-button"
              onClick={() => formik.handleSubmit()}
            >
              Lưu
            </button>
          </div>
        </>
      )}
    </div>
  );

  // task
  const { errorInsert, insertData, stepsData } = useSelector((state: any) => ({
    insertData: state.Tasks.insertData,
    errorInsert: state.Tasks.errorInsert,
    stepsData: state.Steps.stepsData,
  }));

  const { titlesData, insertTitle, insertTitleError } = useSelector(
    (state: any) => ({
      titlesData: state.Titles.titlesData,
      insertTitle: state.Titles.insertData,
      insertTitleError: state.Titles.errorInsert,
    }),
    shallowEqual
  );

  const selectedStepName = stepsData?.result?.steps.find(
    (e: any) => e.id === selectedStepsId
  )?.name;

  useEffect(() => {
    if (selectedWorkTaskId) {
      dispatch(
        getTitleDataAll({
          workTaskId: selectedWorkTaskId,
          step_name: selectedStepName,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (titlesData?.result) {
      const resultArray = titlesData.result.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      setArrTitle(resultArray);
    }
  }, [titlesData, selectedWorkTaskId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      titleName: "",
      files: null,
      workTaskId: selectedWorkTaskId,
    },
    validationSchema: Yup.object().shape({
      titleName: Yup.object().required("Tên hạng mục không được để trống"),
    }),
    onSubmit: (values: any) => {
      const dataToSend = {
        name: values.name,
        description: values.description,
        titleName: values.titleName.name,
        workTaskId: values.workTaskId,
        files: uploadedFiles || [],
      };
      dispatch(addTasks(dataToSend));
    },
  });

  useEffect(() => {
    if (insertData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Tạo yêu cầu thành công",
        life: 3000,
      });
    }
    formik.resetForm();
    setUploadedFiles([]);
    toggleIsSpending();
    toggleIsLoad();
    onClose();
    setArrTitle([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertData]);

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Tạo yêu cầu thất bại",
        life: 3000,
      });
    }
  }, [errorInsert]);

  useEffect(() => {
    if (insertTitle) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Thêm hạng mục thành công",
        life: 3000,
      });
    }
    setVisible(false);
    setIsLoading(!isLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertTitle]);

  useEffect(() => {
    if (insertTitleError) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Thêm hạng mục thất bại",
        life: 3000,
      });
    }
  }, [insertTitleError]);

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={open}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          onClose();
          formik.resetForm();
          setUploadedFiles([]);
          setArrTitle([]);
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <h3 className="font-semibold text-grey-300 mb-3">Hạng mục</h3>
          <div className="w-full flex flex-row items-center align-items-center justify-content-center">
            <Dropdown
              value={formik.values.titleName}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("titleName", e.value);
              }}
              options={arrTitle}
              optionLabel="titleName"
              filter
              valueTemplate={selectedTitleTemplate}
              itemTemplate={titleOptionTemplate}
              className="w-full h-3rem"
              invalid={
                formik.touched.titleName && formik.errors.titleName
                  ? true
                  : false
              }
            />
            {roleName && (
              <>
                <i
                  className="pi pi-plus-circle custom-tooltip-btn"
                  style={{
                    fontSize: "2rem",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setVisible(true)}
                ></i>
                <Tooltip target=".custom-tooltip-btn" position="bottom">
                  Thêm
                </Tooltip>
              </>
            )}
          </div>
          {formik.touched.titleName && formik.errors.titleName ? (
            <p style={{ color: "red", marginTop: "5px", fontSize: "0.9rem" }}>
              {String(formik.errors.titleName)}
            </p>
          ) : null}
        </div>
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <h3 className="font-semibold text-grey-300 mb-3">Việc cần làm</h3>
          <InputTextarea
            autoResize
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="name"
            invalid={formik.touched.name && formik.errors.name ? true : false}
            className="w-full"
          />
          {formik.touched.name && formik.errors.name ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.name)}
            </p>
          ) : null}
        </div>
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <h3 className="font-semibold text-grey-300 mb-3">Mô tả</h3>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={8}
            name="description"
            style={{ width: "100%" }}
          />
        </div>
        {uploadedFiles.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between align-items-center h-5rem w-full"
          >
            <div className="flex items-center w-full">
              {/* <Avatar
                className="p-overlay-badge"
                image={item.iconUrl}
                size="large"
              ></Avatar> */}
              <Image
                className="p-overlay-badge"
                src={item.iconUrl} // Nếu không có imageUrl, sử dụng defaultImage
                width="40"
                height="40"
                preview
              />
              <p className="font-semibold text-grey-300 mt-3 ml-3">
                {item.fileName}
              </p>
            </div>
            <p className="text-grey-300">{item.fileSize}</p>
          </div>
        ))}
      </Dialog>

      <Dialog
        header="Thêm hạng mục"
        visible={visible}
        className="sm:w-5 w-8 xl:w-4 lg:w-4"
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        footer={footerContentTitle}
        draggable={false}
        resizable={false}
      >
        <h3 className="font-semibold text-grey-300 mb-3">Tên hạng mục</h3>
        <div className="w-full flex flex-row items-center align-items-center justify-content-center">
          <InputText
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            id="name"
            name="name"
            autoComplete="on"
            type="text"
            className="w-full p-3"
          />
        </div>
      </Dialog>
    </div>
  );
}
