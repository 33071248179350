import React, { useRef, useState, useContext, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { deleteTasks, updateTasks } from "../../../store/tasks/slice";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { addTitles, getTitleDataAll } from "../../../store/titles/slice";
import ModelDelete from "../../../components/ModalDelete";
import { Image } from "primereact/image";
interface TitleProps {
  id: number;
  name: string;
}

export default function ModelEditTask({
  open,
  onClose,
  selectedDetailTaskEdit,
  selectedDetailTitleEdit,
}: {
  open: boolean;
  onClose: any;
  selectedDetailTaskEdit: any;
  selectedDetailTitleEdit: any;
}) {
  const dispatch = useDispatch();
  const { selectedWorkTaskId, selectedStepsId, roleName } =
    useContext(ProjectContext);
  const toast = useRef<Toast>(null);
  const [arrTitle, setArrTitle] = useState<TitleProps[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [newCategory, setNewCategory] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      fileUrl: string;
      fileName: string;
      fileSize: string;
      iconUrl: string;
      file: File;
      isSelected: boolean;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => {
        const fileUrl = URL.createObjectURL(file);
        let iconUrl = "";

        // Determine icon based on file extension
        if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
          iconUrl = "/images/image/excel.png";
        } else if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
          iconUrl = "/images/image/word.png";
        } else if (
          file.name.endsWith(".jpg") ||
          file.name.endsWith(".jpeg") ||
          file.name.endsWith(".png") ||
          file.name.endsWith(".gif") ||
          file.name.endsWith(".bmp")
        ) {
          iconUrl = fileUrl; // Use the fileUrl itself as the icon for image files
        } else {
          iconUrl = "/images/image/default.png"; // Optional: Default icon for other file types
        }

        return {
          fileUrl,
          fileName: file.name,
          fileSize: `${(file.size / (1024 * 1024)).toFixed(2)}\u00A0MB`, // Use a non-breaking space
          iconUrl,
          file, // Save the file object itself
          isSelected: false,
        };
      });
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleSave = () => {
    if (newCategory.trim()) {
      dispatch(
        addTitles({ workTaskId: selectedWorkTaskId, name: newCategory })
      );
    } else {
      // Hiển thị thông báo lỗi nếu không có nội dung
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Vui lòng nhập tên hạng mục",
        life: 3000,
      });
      return;
    }
  };

  useEffect(() => {
    if (arrTitle && insertTitle && newCategory) {
      const newItem = arrTitle?.find((e: any) => e.name === newCategory);
      formik.setFieldValue("titleId", newItem?.id);
      setNewCategory("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrTitle]);
  const fileOpject = selectedFiles?.map((fileName) => ({ key: fileName }));

  const footerContentTitle = (
    <div>
      {roleName === "pm" && (
        <>
          <button
            className="custom-button"
            onClick={() => {
              setVisible(false);
              setUploadedFiles([]);
            }}
            style={{ backgroundColor: "#808080", color: "#ffffff" }}
          >
            Huỷ
          </button>
          <button className="custom-button" onClick={() => handleSave()}>
            Lưu
          </button>
        </>
      )}
    </div>
  );

  const selectedTitleTemplate = (option: TitleProps, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const titleOptionTemplate = (option: TitleProps) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Sửa công việc</span>
    </div>
  );

  const acceptDelete = () => {
    if (selectedDetailTaskEdit) {
      dispatch(deleteTasks(selectedDetailTaskEdit?.id));
      setModalDelete(false);
    }
  };

  const footerContent = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {roleName === "pm" && (
        <>
          <div className="button-container">
            <input
              type="file"
              name="files"
              id="upload"
              hidden
              accept=".xlsx,.xls,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="upload" className="upload-file">
              <i className="pi pi-paperclip"></i> Đính kèm
            </label>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="custom-button"
              onClick={() => setModalDelete(true)}
              style={{ backgroundColor: "red", color: "#ffffff" }}
            >
              Xóa
            </button>
            <button
              className="custom-button"
              onClick={() => formik.handleSubmit()}
            >
              Lưu
            </button>
          </div>
        </>
      )}
    </div>
  );

  const handleDeleteToggle = (fileName: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.fileName === fileName
          ? { ...file, isSelected: !file.isSelected }
          : file
      )
    );

    setSelectedFiles((prevSelected) => {
      if (prevSelected.includes(fileName)) {
        return prevSelected.filter((name) => name !== fileName);
      } else {
        return [...prevSelected, fileName];
      }
    });
  };

  // task
  const {
    errorUpdateTask,
    updateDataTask,
    stepsData,
    listTaskData,
    deleteData,
    errorDelete,
  } = useSelector((state: any) => ({
    updateDataTask: state.Tasks.updateData,
    errorUpdateTask: state.Tasks.errorUpdate,
    stepsData: state.Steps.stepsData,
    listTaskData: state.Tasks.tasksData,
    deleteData: state.Tasks.deleteData,
    errorDelete: state.Tasks.errorDelete,
  }));

  const { titlesData, insertTitle, insertTitleError } = useSelector(
    (state: any) => ({
      titlesData: state.Titles.titlesData,
      insertTitle: state.Titles.insertData,
      insertTitleError: state.Titles.errorInsert,
    }),
    shallowEqual
  );

  const selectedStepName = stepsData?.result?.steps.find(
    (e: any) => e.id === selectedStepsId
  )?.name;

  useEffect(() => {
    if (selectedWorkTaskId) {
      dispatch(
        getTitleDataAll({
          workTaskId: selectedWorkTaskId,
          step_name: selectedStepName,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (titlesData?.result) {
      const resultArray = titlesData.result.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      setArrTitle(resultArray);
    }
  }, [titlesData, selectedWorkTaskId]);

  useEffect(() => {
    if (listTaskData?.result?.files && open) {
      const initialFiles = listTaskData.result.files.map((file: any) => {
        let iconUrl;
        let fileType = file.type;

        // Determine the icon based on the file extension
        if (file.key.endsWith(".xlsx") || file.key.endsWith(".xls")) {
          iconUrl = "/images/image/excel.png";
          fileType = "excel";
        } else if (file.key.endsWith(".doc") || file.key.endsWith(".docx")) {
          iconUrl = "/images/image/word.png";
          fileType = "word";
        } else if (fileType === "image") {
          iconUrl = file.value; // Use the actual image URL if it's an image
        } else {
          iconUrl = "/images/image/default.png"; // Default icon for other file types
        }

        return {
          fileUrl: file.value,
          fileName: file.key,
          fileSize: "Unknown", // You can replace this with the actual size if available
          iconUrl,
          fileType,
          file: null, // No actual file information, just the URL
        };
      });

      setUploadedFiles((prevFiles) => [...initialFiles, ...prevFiles]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedDetailTaskEdit ? selectedDetailTaskEdit.id : "",
      name: selectedDetailTaskEdit ? selectedDetailTaskEdit.name : "",
      description: selectedDetailTaskEdit
        ? selectedDetailTaskEdit.description
        : "",
      titleId: selectedDetailTitleEdit ? selectedDetailTitleEdit?.id : "",
      files: null,
      workTaskId: selectedWorkTaskId,
      isDone: selectedDetailTaskEdit ? selectedDetailTaskEdit.isDone : false,
    },
    validationSchema: Yup.object().shape({
      titleId: Yup.string().required("Tên hạng mục không được để trống"),
    }),
    onSubmit: (values: any) => {
      const dataToSend = {
        id: values.id,
        name: values.name,
        description: values.description,
        titleId: values.titleId,
        workTaskId: values.workTaskId,
        isDone: values.isDone,
        files: uploadedFiles || [],
        files_remove: fileOpject || [],
      };
      dispatch(updateTasks(dataToSend));
    },
  });

  useEffect(() => {
    if (updateDataTask) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Thành công",
        life: 3000,
      });
    }
    formik.resetForm();
    setUploadedFiles([]);
    onClose();
    setArrTitle([]);
    setSelectedFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataTask]);

  useEffect(() => {
    if (errorUpdateTask) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Chỉnh sửa thất bại",
        life: 3000,
      });
    }
  }, [errorUpdateTask]);

  useEffect(() => {
    if (insertTitle) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Thêm hạng mục thành công",
        life: 3000,
      });
    }
    setVisible(false);
    setIsLoading(!isLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertTitle]);

  useEffect(() => {
    if (insertTitleError) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Thêm hạng mục thất bại",
        life: 3000,
      });
    }
  }, [insertTitleError]);

  useEffect(() => {
    if (deleteData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Xóa công việc thành công`,
        life: 3000,
      });
    }
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData]);

  useEffect(() => {
    if (errorDelete) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Xóa công việc thất bại",
        life: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDelete]);

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={open}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          onClose();
          formik.resetForm();
          setUploadedFiles([]);
          setArrTitle([]);
          setSelectedFiles([]);
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <h3 className="font-semibold text-grey-300 mb-3">Hạng mục</h3>
          <div className="w-full flex flex-row items-center align-items-center justify-content-center">
            <Dropdown
              value={arrTitle.find((e) => e.id === formik.values.titleId)}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("titleId", e.value.id);
              }}
              options={arrTitle}
              optionLabel="name"
              filter
              valueTemplate={selectedTitleTemplate}
              itemTemplate={titleOptionTemplate}
              className="w-full h-3rem"
              invalid={
                formik.touched.titleId && formik.errors.titleId ? true : false
              }
            />
            {roleName === "pm" && (
              <>
                <i
                  className="pi pi-plus-circle custom-tooltip-btn"
                  style={{
                    fontSize: "2rem",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setVisible(true)}
                ></i>
                <Tooltip target=".custom-tooltip-btn" position="bottom">
                  Thêm
                </Tooltip>
              </>
            )}
          </div>
          {formik.touched.titleName && formik.errors.titleName ? (
            <p style={{ color: "red", marginTop: "5px", fontSize: "0.9rem" }}>
              {String(formik.errors.titleName)}
            </p>
          ) : null}
        </div>
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <h3 className="font-semibold text-grey-300 mb-3">Việc cần làm</h3>
          <InputTextarea
            autoResize
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="name"
            invalid={formik.touched.name && formik.errors.name ? true : false}
            className="w-full"
          />
          {formik.touched.name && formik.errors.name ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.name)}
            </p>
          ) : null}
        </div>
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <h3 className="font-semibold text-grey-300 mb-3">Mô tả</h3>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={8}
            name="description"
            style={{ width: "100%" }}
          />
        </div>
        {uploadedFiles.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between align-items-center h-5rem w-full"
          >
            <div className="flex items-center w-full">
              <Image
                className="p-overlay-badge"
                src={item.iconUrl} // Nếu không có imageUrl, sử dụng defaultImage
                width="40"
                height="40"
                preview
              />
              <p className="font-semibold text-grey-300 mt-3 ml-3">
                {item.fileName}
              </p>
            </div>
            <p className="text-grey-300">{item.fileSize}</p>
            <span
              className={`delete-icon ${item.isSelected ? "selected" : ""}`}
              onClick={() => handleDeleteToggle(item.fileName)}
              // style={{}}
            >
              &times;
            </span>
          </div>
        ))}
      </Dialog>

      <Dialog
        header="Thêm hạng mục"
        visible={visible}
        className="sm:w-5 w-8 xl:w-4 lg:w-4"
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        footer={footerContentTitle}
        draggable={false}
        resizable={false}
      >
        <h3 className="font-semibold text-grey-300 mb-3">Tên hạng mục</h3>
        <div className="w-full flex flex-row items-center align-items-center justify-content-center">
          <InputText
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            id="name"
            name="name"
            autoComplete="on"
            type="text"
            className="w-full p-3"
          />
        </div>
      </Dialog>

      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa công việc này không?
          </div>
        }
      />
    </div>
  );
}
