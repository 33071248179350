import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import * as URL_API from "../../api/url_helper";
import {
  addCommentSuccess,
  addCommentFail,
  getCommentDataAllSuccess,
  getCommentDataAllFail,
} from "./slice";
import { getCommentDataById } from "../../api/backend_helper";
import axios from "axios";

function* createCommentSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const { content, taskId, files } = action.payload;
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach((file: any) => {
        if (file.file) {
          formData.append("files", file.file, file.fileName);
        }
      });
    }
    formData.append("content", content);
    formData.append("taskId", taskId);
    const response = yield call(axios.post, URL_API.CREATE_COMMENT, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };
    yield put(addCommentSuccess(dataUpdate));
  } catch (error) {
    yield put(addCommentFail("Create comment failed"));
  }
}

function* fetchCommentDataAll(
  action: PayloadAction<String>
): Generator<any, void, any> {
  try {
    const response = yield call(getCommentDataById, action.payload);
    yield put(getCommentDataAllSuccess(response));
  } catch (error) {
    yield put(getCommentDataAllFail("Failed to fetch comment data"));
  }
}

function* CommentSaga(): Generator<any, void, any> {
  yield takeLatest("comment/getCommentDataAll", fetchCommentDataAll);
  yield takeLatest("comment/addComment", createCommentSaga);
}

export default CommentSaga;
