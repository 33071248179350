import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";
const ActionTable = ({
    onEdit,
    onDelete,
}: {
    onEdit: () => void;
    onDelete: () => void;
}) => {
    const menuAction = useRef<Menu>(null);

    let viewDetail = false;
    const actions = [];

    actions.push({
        label: "Chỉnh sửa",
        icon: "pi pi-pen-to-square",
        command: onEdit,
    });

    actions.push({
        label: "Xóa",
        icon: (
            <i
                className="pi pi-trash"
                style={{ color: "red", marginRight: "8px" }}
            />
        ),
        command: onDelete,
    });

    return (
        <div className="flex justify-content-end align-items-center">
            <Menu
                model={actions}
                popup
                ref={menuAction}
                className="action-menu"
            />
            <Button
                icon="pi pi-ellipsis-v"
                onClick={(e) => menuAction.current?.toggle(e)}
                className="p-button-text"
            />
        </div>
    );
};

export default ActionTable;
