import { Avatar } from "primereact/avatar";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { useContext, useEffect, useRef, useState } from "react";
import ModelSpendingTask from "./model-spending-task";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import ModelNoteTask from "./model-note-task";
import { TabMenu } from "primereact/tabmenu";
import ModelSendMessage from "./model-send-message-task";
import ModelListImageTask from "./model-list-image-task";
import ModelListFileTask from "./model-list-file-task";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  addWorkTask,
  getWorkTaskAll,
  setDataNull as setDataNullTask,
} from "../../../store/work-tasks/slice";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import {
  getTitleDataAll,
  setDataNull as setDataNullTitle,
} from "../../../store/titles/slice";
import { updateTasks } from "../../../store/tasks/slice";
import { getNotesDataAll } from "../../../store/notes/slice";
import { Tooltip } from "primereact/tooltip";
import { StepStatus } from "../../../components/Interface"; // Trạng thái của status
import { updateDataConfirm, updateDataStart } from "../../../store/steps/slice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { setDataNull } from "../../../store/steps/slice";
import { getTaskDataAll } from "../../../store/tasks/slice";
import { getCommentDataAll } from "../../../store/comment/slice";
import ModelTimesTask from "./model-times-task";
import ModelEditTask from "./model-edit-task";
import ModelEditWorkTask from "./model-edit-work-task";
import ModelEditTitleTask from "./model-edit-title-task";
import ModelEditNote from "./model-edit-note";

interface Task {
  id: string;
  name: string;
  description: string;
  isDone: boolean;
  numImages: number;
  numComments: number;
}

const DetailTask = () => {
  const menuLeft = useRef<Menu>(null);
  const toast = useRef<Toast>(null);
  const [openSpending, setOpenSpending] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [openSending, setOpenSending] = useState<boolean>(false);
  const [openListImage, setOpenListImage] = useState<boolean>(false);
  const [openListFile, setOpenListFile] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    selectedProjectId,
    selectedStepsId,
    selectedWorkTaskId,
    setSelectedWorkTaskId,
    roleName,
    isLoad,
    setSelectedTaskId,
    setSelectedTitleTask,
    isNextSteps,
    setSelectedEditTask,
    setCheckNoteEdit,
    selectedStatus,
    selectedEditTask,
  } = useContext(ProjectContext);
  const [selectedCheckBox, setSelectedCheckBox] = useState<Task[]>([]);
  const [openTimes, setOpenTimes] = useState<boolean>(false);
  const [openEditTask, setopenEditTask] = useState<boolean>(false);
  const [selectedDetailTaskEdit, setSelectedDetailTaskEdit] = useState<any>({});
  const [selectedDetailTitleEdit, setSelectedDetailTitleEdit] = useState<any>(
    {}
  );
  const [openEditWorkTask, setOpenEditWorkTask] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>(null);
  const [openEditTitle, setOpenEditTitle] = useState<boolean>(false);
  const [rowDataTitle, setRowDataTitle] = useState<any>(null);
  const [openEditNote, setopenEditNote] = useState<boolean>(false);
  const [rowDataNote, setRowDataNote] = useState<any>(null);

  // work-group
  const { updateDataTask, updateWorkTaskData } = useSelector(
    (state: any) => ({
      updateDataTask: state.Tasks.updateData,
      updateWorkTaskData: state.WorkTask.updateData,
    }),
    shallowEqual
  );

  // Hiển thị tên trạng thái dựa trên giá trị của trạng thái hiện tại
  const getStatusLabel = () => {
    switch (selectedStatus) {
      case StepStatus.COMPLETED:
        return "Đã hoàn thành";
      case StepStatus.DOING:
        return "Hoàn thành";
      case StepStatus.PENDING:
        return "Bắt đầu";
      default:
        return "";
    }
  };

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    let _selectedCheckBox = [...selectedCheckBox];
    if (e.checked) _selectedCheckBox.push(e.value);
    else
      _selectedCheckBox = _selectedCheckBox.filter(
        (cate) => cate.id !== e.value.id
      );

    setSelectedTaskId(e.value.id);
    setSelectedCheckBox(_selectedCheckBox);
    const dataToSend = {
      id: e.value.id,
      type: "upload",
      isDone: e.checked,
      skipUpdate: true, // Thêm cờ này
    };
    dispatch(updateTasks(dataToSend));
  };

  const handleTitleData = () => {
    dispatch(
      getTitleDataAll({
        workTaskId: selectedWorkTaskId,
        step_name: selectedStepName,
      })
    );
  };

  const item: MenuItem[] = [
    {
      items: [
        {
          label: "Việc cần làm",
          icon: "pi pi-book",
          command: () => {
            setOpenSpending(true);
            handleTitleData();
          },
        },
        {
          label: "Ghi chú",
          icon: "pi pi-pen-to-square",
          command: () => {
            setVisible(true);
          },
        },
      ],
    },
  ];

  const items: MenuItem[] = [
    {
      label: "Việc cần làm",
      icon: "pi pi-book",
      style: { fontSize: "16px" }, // Thay đổi kích thước icon
    },
    {
      label: "Ghi chú",
      icon: "pi pi-pen-to-square",
      style: { fontSize: "16px" }, // Thay đổi kích thước icon
    },
  ];

  //work-group
  const {
    insertData,
    taskGroups,
    tasksNote,
    insertDataTask,
    deleteData,
    errorDelete,
    setDeadlineData,
    updateTitleData,
    insertNotesData,
    updateNotesData,
    deleteNotesData,
  } = useSelector(
    (state: any) => ({
      insertData: state.WorkTask.insertData,
      taskGroups: state.WorkTask.workTaskData,
      tasksNote: state.Notes.notesData,
      loading: state.WorkTask.loading,
      updateData: state.Tasks.updateData,
      errorUpdate: state.Tasks.errorUpdate,
      insertDataTask: state.Tasks.insertData,
      deleteData: state.Tasks.deleteData,
      errorDelete: state.Tasks.errorDelete,
      setDeadlineData: state.Tasks.setDeadlineData,
      updateTitleData: state.Titles.updateData,
      insertNotesData: state.Notes.insertData,
      updateNotesData: state.Notes.updateData,
      deleteNotesData: state.Notes.deleteData,
    }),
    shallowEqual
  );

  // Title
  const { itemsfunction, stepsData } = useSelector(
    (state: any) => ({
      itemsfunction: state.Titles.titlesData,
      stepsData: state.Steps.stepsData,
    }),
    shallowEqual
  );

  // Steps
  const { updateStart, errorUpdateStart } = useSelector(
    (state: any) => ({
      updateStart: state.Steps.updateStart,
      errorUpdateStart: state.Steps.errorUpdateStart,
    }),
    shallowEqual
  );

  const { errorUpdateConfirm, updateConfirm } = useSelector(
    (state: any) => ({
      updateConfirm: state.Steps.updateConfirm,
      errorUpdateConfirm: state.Steps.errorUpdateConfirm,
    }),
    shallowEqual
  );

  const acceptComplete = () => {
    const values = {
      projectId: selectedProjectId?.id,
      stepId: selectedStepsId,
    };
    dispatch(updateDataConfirm(values));
  };

  const acceptStart = () => {
    const values = {
      projectId: selectedProjectId?.id,
      stepId: selectedStepsId,
    };
    dispatch(updateDataStart(values));
  };

  // notes
  useEffect(() => {
    if (selectedWorkTaskId && activeIndex === 1) {
      dispatch(getNotesDataAll(selectedWorkTaskId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedWorkTaskId,
    isLoad,
    activeIndex,
    insertNotesData,
    updateNotesData,
    deleteNotesData,
  ]);

  const confirmStart = () => {
    confirmDialog({
      message: "Bạn có muốn bắt đầu mục công việc này không?",
      header: "Xác nhận",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "acceptStart",
      accept: acceptStart,
      acceptLabel: "Đồng ý",
      rejectLabel: "Hủy",
    });
  };

  const confirmComplete = () => {
    confirmDialog({
      message: "Bạn có muốn hoàn thành mục công việc này không?",
      header: "Xác nhận",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: acceptComplete,
      acceptLabel: "Đồng ý",
      rejectLabel: "Hủy",
    });
  };

  useEffect(() => {
    if (updateConfirm) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Thành công ",
        life: 3000,
      });
      dispatch(setDataNull());
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConfirm]);

  useEffect(() => {
    if (errorUpdateConfirm) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Thất bại`,
        life: 3000,
      });
    }
  }, [errorUpdateConfirm]);

  useEffect(() => {
    if (errorUpdateStart) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Thất bại`,
        life: 3000,
      });
    }
  }, [errorUpdateStart]);

  useEffect(() => {
    if (updateStart) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Thành công",
        life: 3000,
      });
      dispatch(setDataNull());
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStart]);

  useEffect(() => {
    if (selectedProjectId && selectedStepsId) {
      dispatch(
        getWorkTaskAll({
          projectId: selectedProjectId.id,
          stepsId: selectedStepsId,
          query: {}, // thay đổi `query` tùy theo nhu cầu
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStepsId, selectedProjectId]);

  useEffect(() => {
    if (
      (selectedProjectId &&
        selectedStepsId &&
        (updateDataTask || insertData || insertDataTask || deleteData)) ||
      updateWorkTaskData
    ) {
      dispatch(
        getWorkTaskAll({
          projectId: selectedProjectId.id,
          stepsId: selectedStepsId,
          query: {}, // thay đổi `query` tùy theo nhu cầu
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedStepsId,
    selectedProjectId,
    updateDataTask,
    insertData,
    insertDataTask,
    deleteData,
    updateWorkTaskData,
  ]);

  // list api theo tên của title khi ấn vào kiểm thử
  const selectedStepName = stepsData?.result?.steps.find(
    (e: any) => e.id === selectedStepsId
  )?.name;

  useEffect(() => {
    if (taskGroups && taskGroups.result.items && !selectedWorkTaskId) {
      const id = taskGroups?.result?.items.find(
        (item: any) => item.name === "Yêu cầu giao diện"
      )?.id;
      setSelectedWorkTaskId(id);
      setSelectedTitleTask("Yêu cầu giao diện");
    }

    if (taskGroups && selectedWorkTaskId) {
      if (taskGroups.result.items.length > 0) {
        const index = taskGroups.result.items.findIndex(
          (item: any) => item.id === selectedWorkTaskId
        );

        const firstId = taskGroups?.result?.items[0]?.id;
        const firstName = taskGroups?.result?.items[0]?.name;

        if (firstId && (index === -1 || index === 0)) {
          setSelectedWorkTaskId(firstId);
          setSelectedTitleTask(firstName);
          return;
        }
      } else {
        dispatch(setDataNullTitle());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskGroups]);

  useEffect(() => {
    if (
      selectedStepName === "Thiết kế giao diện" ||
      selectedStepName === "Phát triển & Kiểm thử"
    ) {
      setSelectedCheckBox(
        itemsfunction?.result.filter((task: any) => task.isDone)
      );
    } else {
      if (itemsfunction) {
        setSelectedCheckBox(
          itemsfunction?.result
            .flatMap((category: any) => category?.tasks)
            .filter((task: any) => task?.isDone)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsfunction, selectedStepName]);

  useEffect(() => {
    if (selectedWorkTaskId && taskGroups?.result?.items?.length > 0) {
      dispatch(
        getTitleDataAll({
          workTaskId: selectedWorkTaskId,
          step_name: selectedStepName,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkTaskId, selectedStepName, taskGroups]);

  useEffect(() => {
    if (
      selectedWorkTaskId &&
      (updateDataTask ||
        insertDataTask ||
        insertData ||
        deleteData ||
        setDeadlineData ||
        updateTitleData)
    ) {
      dispatch(
        getTitleDataAll({
          workTaskId: selectedWorkTaskId,
          step_name: selectedStepName,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedWorkTaskId,
    insertDataTask,
    selectedStepName,
    insertData,
    updateDataTask,
    setDeadlineData,
    updateTitleData,
  ]);

  useEffect(() => {
    if (deleteData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Xóa công việc thành công`,
        life: 3000,
      });
    }
    dispatch(setDataNullTask());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData]);

  useEffect(() => {
    if (insertData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Tạo nhóm thành công",
        life: 3000,
      });
      setOpen(false);
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertData]);

  useEffect(() => {
    if (errorDelete) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Tạo nhóm thất bại`,
        life: 3000,
      });
    }
  }, [errorDelete]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      projectId: selectedProjectId?.id,
      stepId: selectedStepsId,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Tên nhóm không được để trống"),
    }),
    onSubmit: (values: any) => {
      dispatch(addWorkTask(values));
    },
  });

  const handleClick = () => {
    if (selectedStatus === StepStatus.PENDING) {
      confirmStart();
    } else if (selectedStatus === StepStatus.DOING) {
      confirmComplete();
    }
  };

  const [shouldCallHandleClickTask, setShouldCallHandleClickTask] =
    useState(false);
  const [shouldCallHandleClickComment, setShouldCallHandleClickComment] =
    useState(false);
  const [shouldCallHandleClickNotes, setShouldCallHandleClickNotes] =
    useState(false);
  const [shouldCallHandleClickTitle, setShouldCallHandleClickTitle] =
    useState(false);

  useEffect(() => {
    if (shouldCallHandleClickTask && selectedEditTask?.id) {
      dispatch(getTaskDataAll(selectedEditTask.id));
      setShouldCallHandleClickTask(false); // Reset lại để tránh gọi lại hàm không cần thiết
    }
  }, [dispatch, selectedEditTask, shouldCallHandleClickTask]);

  useEffect(() => {
    if (shouldCallHandleClickComment && selectedEditTask?.id) {
      dispatch(getCommentDataAll(selectedEditTask?.id));
      setShouldCallHandleClickComment(false);
    }
  }, [dispatch, selectedEditTask, shouldCallHandleClickComment]);

  useEffect(() => {
    if (shouldCallHandleClickNotes && selectedWorkTaskId) {
      dispatch(getNotesDataAll(selectedWorkTaskId));
      setShouldCallHandleClickNotes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedWorkTaskId, shouldCallHandleClickNotes]);

  useEffect(() => {
    if (shouldCallHandleClickTitle && selectedWorkTaskId && selectedStepName) {
      dispatch(
        getTitleDataAll({
          workTaskId: selectedWorkTaskId,
          step_name: selectedStepName,
        })
      );
      setShouldCallHandleClickTitle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    selectedWorkTaskId,
    selectedStepName,
    shouldCallHandleClickTitle,
  ]);

  useEffect(() => {
    if (
      isNextSteps &&
      (selectedStepName === "Thiết kế giao diện" ||
        selectedStepName === "Phát triển & Kiểm thử")
    ) {
      setActiveIndex(0);
    }
  }, [isNextSteps, selectedStepName]);

  return (
    <>
      <Toast ref={toast} />
      <div className="nav-body-work mx-3 mt-2">
        <div
          className=""
          style={{ backgroundColor: "#FFFFFF", borderRadius: "14px" }}
        >
          <div className="p-2 flex-grow-1 nav-group-work">
            <div className="">
              <div className="flex align-items-center justify-content-between ">
                <h3
                  className="font-semibold text-grey-300 text-left"
                  style={{ fontSize: "18px" }}
                >
                  Nhóm công việc
                </h3>
                {roleName === "pm" && (
                  <button
                    className="custom-button"
                    onClick={handleClick}
                    disabled={selectedStatus === StepStatus.COMPLETED}
                    style={{ fontSize: "14px" }}
                  >
                    {getStatusLabel()}
                  </button>
                )}
              </div>
              <div className="scrollable-container1">
                {selectedStepName === "Thiết kế giao diện" ||
                selectedStepName === "Phát triển & Kiểm thử"
                  ? taskGroups &&
                    taskGroups?.result?.items?.map((item: any) => (
                      <div
                        className={`work-group justify-content-between ${
                          selectedWorkTaskId === item.id
                            ? "selected"
                            : "not-selected"
                        }`}
                        key={item.id}
                        onClick={() => {
                          setSelectedWorkTaskId(item.id);
                          setSelectedTitleTask(item.name);
                        }}
                        style={{
                          cursor: "pointer",
                          borderRadius: "8px", // Thêm border-radius nếu muốn bo góc
                          padding: "15px", // Thêm padding nếu muốn nội dung cách viền một chút
                          userSelect: "none",
                        }}
                      >
                        <h4
                          className="font-normal"
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          {item.name}
                        </h4>
                        <div className="checkbox-container flex align-items-center mr-3">
                          <i className="pi pi-check-square mr-3"></i>
                          <div
                            style={{
                              width: "65px",
                            }}
                          >
                            <label
                              htmlFor={`ingredient${item.id}`}
                              style={{ width: "40px" }}
                            >
                              {item.min}/{item.max}
                            </label>
                            <i className="pi pi-chevron-right ml-2"></i>
                          </div>
                        </div>
                      </div>
                    ))
                  : taskGroups &&
                    taskGroups.result?.items?.map((task: any) => (
                      <div
                        className={`work-group justify-content-between ${
                          selectedWorkTaskId === task.id
                            ? "selected"
                            : "not-selected"
                        }`}
                        key={task.id}
                        onClick={() => {
                          setSelectedWorkTaskId(task.id);
                          setSelectedTitleTask(task.name);
                        }}
                        style={{
                          cursor: "pointer",
                          borderRadius: "8px", // Thêm border-radius nếu muốn bo góc
                          padding: "15px", // Thêm padding nếu muốn nội dung cách viền một chút
                          userSelect: "none",
                        }}
                      >
                        <h4
                          className="font-normal"
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          {task.name}
                        </h4>
                        <div className="checkbox-container flex justify-content-end align-items-center  ">
                          {roleName === "pm" &&
                            !(
                              task.name === "Yêu cầu giao diện" ||
                              task.name === "Yêu cầu chức năng" ||
                              task.name === "Lập kế hoạch"
                            ) && (
                              <i
                                className="pi pi-pencil mr-3"
                                onClick={() => {
                                  setRowData(task);
                                  setOpenEditWorkTask(true);
                                }}
                              ></i>
                            )}
                          <i className="pi pi-check-square mr-3"></i>
                          <div
                            style={{
                              width: "65px",
                            }}
                          >
                            <label
                              htmlFor={`ingredient${task.id}`}
                              // style={{ width: "45px" }}
                            >
                              {task.min}/{task.max}
                            </label>
                            <i className="pi pi-chevron-right ml-2"></i>
                          </div>
                        </div>
                      </div>
                    ))}
                {open ? (
                  <>
                    <div className="card mt-3" style={{ width: "100%" }}>
                      <InputTextarea
                        autoResize
                        placeholder="Tạo nhóm công việc.."
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        rows={5}
                        name="name"
                        style={{ width: "100%" }}
                        invalid={
                          formik.touched.name && formik.errors.name
                            ? true
                            : false
                        }
                        className="w-full"
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <p
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "0.9rem",
                            marginLeft: "0",
                            textAlign: "left",
                          }}
                        >
                          {String(formik.errors.name)}
                        </p>
                      ) : null}
                    </div>
                    <div className="button-container flex justify-content-end mt-2">
                      <button
                        className="custom-button"
                        onClick={() => setOpen(false)}
                        style={{
                          backgroundColor: "#808080",
                          color: "#ffffff",
                          fontSize: "14px",
                        }}
                      >
                        Huỷ
                      </button>
                      <button
                        className="custom-button"
                        style={{ fontSize: "14px" }}
                        onClick={() => formik.handleSubmit()}
                      >
                        Lưu
                      </button>
                    </div>
                  </>
                ) : null}
                {roleName === "pm" && !isNextSteps && open === false && (
                  <div className="button-container flex justify-content-start mt-3">
                    <button
                      className="custom-button"
                      onClick={() => setOpen(true)}
                      style={{ fontSize: "14px" }}
                    >
                      Thêm một mục
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            borderRadius: "14px",
          }}
        >
          <div
            className="text-center p-2 flex-grow-1 nav-detail-work"
            // style={{ height: "55vh" }}
          >
            <div className="flex align-items-center justify-content-between ">
              <h3
                className={`font-semibold text-grey-300 text-left ${
                  isNextSteps ? "mt-4" : ""
                }`}
                style={{ fontSize: "18px" }}
              >
                Chi tiết công việc
              </h3>
              {roleName === "pm" && !isNextSteps && (
                <div className="flex align-items-center">
                  <Menu
                    model={item}
                    popup
                    ref={menuLeft}
                    id="popup_menu_left"
                  />
                  <button
                    className="custom-button"
                    onClick={(event) => menuLeft.current?.toggle(event)}
                    style={{ fontSize: "14px" }}
                  >
                    Thêm
                  </button>
                </div>
              )}
            </div>
            {!isNextSteps && (
              <div className="">
                <TabMenu
                  model={items}
                  activeIndex={activeIndex}
                  onTabChange={(e) => {
                    setActiveIndex(e.index);
                    setCheckNoteEdit(false);
                  }}
                />
              </div>
            )}

            {/* // Việc cần làm */}

            {activeIndex === 0 && (
              <div className="scrollable-container">
                {isNextSteps ? (
                  <>
                    {itemsfunction &&
                      itemsfunction?.result?.map((task: any) => {
                        const isInSelectedCheckBox = selectedCheckBox?.some(
                          (checkboxItem) => checkboxItem.id === task.id
                        );
                        return (
                          <div key={task.id}>
                            <div className="flex align-items-center h-5em md:h-3rem justify-content-between my-2">
                              <div className="mr-3">
                                {roleName && (
                                  <Checkbox
                                    inputId={`ingredient-${task.id}`}
                                    name="task"
                                    value={task}
                                    onChange={onCheckBoxChange}
                                    checked={isInSelectedCheckBox}
                                  />
                                )}
                              </div>
                              <div
                                className="flex align-items-start w-full"
                                // style={{
                                //   cursor: "pointer",
                                // }}
                              >
                                <div className="flex flex-column">
                                  <p
                                    className={`font-normal text-grey-300 text-left ${
                                      isInSelectedCheckBox
                                        ? "strikethrough"
                                        : ""
                                    }`}
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {task.name}
                                  </p>
                                  <p
                                    className={`font-light text-grey-300 text-left ${
                                      isInSelectedCheckBox
                                        ? "strikethrough"
                                        : ""
                                    }`}
                                  >
                                    {task.description}
                                  </p>
                                </div>
                              </div>
                              <div className="flex align-items-center">
                                <div className="flex align-items-center gap-2 mr-3">
                                  <i
                                    className="pi pi-image icon-hover"
                                    onClick={() => {
                                      setOpenListImage(true);
                                      setSelectedTitleTask(task.name);
                                      setSelectedEditTask(task);
                                      setShouldCallHandleClickTask(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "22px", // Tăng kích thước icon
                                    }}
                                  ></i>
                                  <i
                                    className="pi pi-paperclip icon-hover"
                                    onClick={() => {
                                      setOpenListFile(true);
                                      setSelectedTitleTask(task.name);
                                      setSelectedEditTask(task);
                                      setShouldCallHandleClickTask(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "22px", // Tăng kích thước icon
                                    }}
                                  ></i>
                                  <i
                                    className="pi pi-comments icon-hover"
                                    onClick={() => {
                                      setOpenSending(true);
                                      setSelectedTitleTask(task.name);
                                      setSelectedEditTask(task);
                                      setShouldCallHandleClickComment(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "22px", // Tăng kích thước icon
                                    }}
                                  ></i>
                                  <i
                                    className="pi pi-clock icon-hover"
                                    onClick={() => {
                                      setOpenTimes(true);
                                      setSelectedTaskId(task.id);
                                      setSelectedEditTask(task);
                                      setShouldCallHandleClickTask(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "22px", // Tăng kích thước icon
                                    }}
                                  ></i>
                                  <Avatar
                                    image="/images/avatas/avatar-null.png"
                                    shape="circle"
                                    className="custom-tooltip-btn"
                                    style={{
                                      cursor: "pointer",
                                      width: "20px", // Tăng kích thước Avatar
                                      height: "20px", // Tăng kích thước Avatar
                                    }}
                                  />
                                  <Tooltip
                                    target=".custom-tooltip-btn"
                                    position="bottom"
                                  >
                                    User
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="custom-divider"></div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <div
                    className={`flex flex-column ${
                      !isNextSteps ? "mt-2" : "mt-2"
                    } word-detail`}
                  >
                    {itemsfunction &&
                      itemsfunction?.result?.map((item: any) => (
                        <div key={item.id}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h3
                              className="font-semibold text-left my-2"
                              style={{
                                fontSize: "16px",
                                marginRight: "8px", // Add some space between the heading and the icon
                              }}
                            >
                              {item.name}
                            </h3>
                            {roleName === "pm" && (
                              <i
                                className="pi pi-pencil"
                                onClick={() => {
                                  setRowDataTitle(item);
                                  setOpenEditTitle(true);
                                }}
                                style={{ fontSize: "12px", cursor: "pointer" }}
                              ></i>
                            )}
                          </div>
                          {item?.tasks?.map((task: any) => {
                            const isInSelectedCheckBox = selectedCheckBox?.some(
                              (checkboxItem) => checkboxItem.id === task.id
                            );
                            return (
                              <div key={task.id}>
                                <div className="flex align-items-center h-5em md:h-3rem justify-content-between mb-2">
                                  <div className="mr-3">
                                    {roleName === "pm" && (
                                      <Checkbox
                                        inputId={`ingredient-${task.id}`}
                                        name="task"
                                        value={task}
                                        onChange={onCheckBoxChange}
                                        checked={isInSelectedCheckBox}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="flex align-items-start w-full"
                                    onClick={() => {
                                      setopenEditTask(true);
                                      setSelectedDetailTitleEdit(item);
                                      setSelectedDetailTaskEdit(task);
                                      setSelectedEditTask(task);
                                      setShouldCallHandleClickTitle(true);
                                      setShouldCallHandleClickTask(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="flex flex-column">
                                      <p
                                        className={`font-normal text-grey-300 text-left ${
                                          isInSelectedCheckBox
                                            ? "strikethrough"
                                            : ""
                                        }`}
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {task.name}
                                      </p>
                                      <p
                                        className={`font-light text-grey-300 text-left ${
                                          isInSelectedCheckBox
                                            ? "strikethrough"
                                            : ""
                                        }`}
                                      >
                                        {task.description}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex align-items-center gap-2 mr-3">
                                    <i
                                      className="pi pi-image icon-hover"
                                      onClick={() => {
                                        setOpenListImage(true);
                                        setSelectedTitleTask(task.name);
                                        setSelectedEditTask(task);
                                        setShouldCallHandleClickTask(true);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "22px", // Tăng kích thước icon
                                      }}
                                    ></i>
                                    <i
                                      className="pi pi-paperclip icon-hover"
                                      onClick={() => {
                                        setOpenListFile(true);
                                        setSelectedTitleTask(task.name);
                                        setSelectedEditTask(task);
                                        setShouldCallHandleClickTask(true);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "22px", // Tăng kích thước icon
                                      }}
                                    ></i>
                                    <i
                                      className="pi pi-comments icon-hover"
                                      onClick={() => {
                                        setOpenSending(true);
                                        setSelectedTitleTask(task.name);
                                        setSelectedEditTask(task);
                                        setShouldCallHandleClickComment(true);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "22px", // Tăng kích thước icon
                                      }}
                                    ></i>
                                    <i
                                      className="pi pi-clock icon-hover"
                                      onClick={() => {
                                        setOpenTimes(true);
                                        setSelectedTaskId(task.id);
                                        setSelectedEditTask(task);
                                        setShouldCallHandleClickTask(true);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "22px", // Tăng kích thước icon
                                      }}
                                    ></i>
                                    <Avatar
                                      image="/images/avatas/avatar-null.png"
                                      shape="circle"
                                      className="custom-tooltip-btn"
                                      style={{
                                        cursor: "pointer",
                                        width: "20px", // Tăng kích thước Avatar
                                        height: "20px", // Tăng kích thước Avatar
                                      }}
                                    />
                                    <Tooltip
                                      target=".custom-tooltip-btn"
                                      position="bottom"
                                    >
                                      User
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="custom-divider"></div>
                              </div>
                            );
                          })}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}

            {/* // ghi chu */}

            {activeIndex === 1 && (
              <div className="scrollable-container">
                <div className=" flex flex-column mt-2 word-detail">
                  {tasksNote &&
                    tasksNote?.result?.items.map((item: any) => (
                      <>
                        <div key={item.id}>
                          <div
                            style={{ cursor: "pointer" }}
                            className="flex align-items-center h-5em md:h-3rem justify-content-between mb-2"
                          >
                            <div
                              className="flex align-items-start w-full"
                              onClick={() => {
                                setopenEditNote(true);
                                setRowDataNote(item);
                                setShouldCallHandleClickNotes(true);
                              }}
                            >
                              <div className="flex flex-column">
                                <p className="text-grey-300 text-left">
                                  {item.description}
                                </p>
                              </div>
                            </div>
                            <div className="flex align-items-center gap-2 mr-3">
                              <i
                                className="pi pi-image icon-hover"
                                onClick={() => {
                                  setOpenListImage(true);
                                  setSelectedEditTask(item);
                                  setSelectedTitleTask(item.description);
                                  setCheckNoteEdit(true);
                                  setShouldCallHandleClickNotes(true);
                                }}
                                style={{
                                  cursor: "pointer",
                                  fontSize: "22px", // Tăng kích thước icon
                                }}
                              ></i>
                              <i
                                className="pi pi-paperclip icon-hover"
                                onClick={() => {
                                  setOpenListFile(true);
                                  setSelectedEditTask(item);
                                  setSelectedTitleTask(item.description);
                                  setCheckNoteEdit(true);
                                  setShouldCallHandleClickNotes(true);
                                }}
                                style={{
                                  cursor: "pointer",
                                  fontSize: "22px", // Tăng kích thước icon
                                }}
                              ></i>
                              <Avatar
                                image="/images/avatas/avatar-null.png"
                                shape="circle"
                                className="custom-tooltip-btn"
                                style={{
                                  cursor: "pointer",
                                  width: "20px", // Tăng kích thước Avatar
                                  height: "20px", // Tăng kích thước Avatar
                                }}
                              />
                              <Tooltip
                                target=".custom-tooltip-btn"
                                position="bottom"
                              >
                                User
                              </Tooltip>
                            </div>
                          </div>
                          <div className="custom-divider"></div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModelSpendingTask
        open={openSpending}
        onClose={() => setOpenSpending(false)}
      />
      <ModelNoteTask visible={visible} onClose={() => setVisible(false)} />
      <ModelListImageTask
        visible={openListImage}
        onClose={() => setOpenListImage(false)}
      />
      <ModelSendMessage
        open={openSending}
        onClose={() => setOpenSending(false)}
      />
      <ModelListFileTask
        open={openListFile}
        onClose={() => setOpenListFile(false)}
      />
      <ConfirmDialog />
      <ModelTimesTask open={openTimes} onClose={() => setOpenTimes(false)} />
      <ModelEditTask
        open={openEditTask}
        onClose={() => setopenEditTask(false)}
        selectedDetailTaskEdit={selectedDetailTaskEdit}
        selectedDetailTitleEdit={selectedDetailTitleEdit}
      />
      <ModelEditWorkTask
        visible={openEditWorkTask}
        onHide={() => setOpenEditWorkTask(false)}
        rowData={rowData}
      />
      <ModelEditTitleTask
        visible={openEditTitle}
        onHide={() => setOpenEditTitle(false)}
        rowData={rowDataTitle}
      />
      <ModelEditNote
        visible={openEditNote}
        onClose={() => setopenEditNote(false)}
        rowData={rowDataNote}
      />
    </>
  );
};

export default DetailTask;
