import { combineReducers } from "redux";
import Auth from "./auth/slice";
import Project from "./project/slice";
import Steps from "./steps/slice";
import WorkTask from "./work-tasks/slice";
import Titles from "./titles/slice";
import Tasks from "./tasks/slice";
import Notes from "./notes/slice";
import Comments from "./comment/slice";
import User from "./user/slice";

const rootReducer = combineReducers({
    Auth,
    Project,
    Steps,
    WorkTask,
    Titles,
    Tasks,
    Notes,
    Comments,
    User,
});

export default rootReducer;
