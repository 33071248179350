import React, { useContext, useEffect } from "react";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";
import { ProjectContext } from "../../contexts/data/ProjectContext";

interface PrivateLayoutProps {
  children: React.ReactNode;
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
  const encodedRole = localStorage.getItem("role");
  const { setRoleName } = useContext(ProjectContext);

  useEffect(() => {
    if (encodedRole) {
      const decodedRole = atob(encodedRole);
      setRoleName(decodedRole);
    }
  }, [encodedRole, setRoleName]);

  return (
    <div className="h-screen w-screen flex flex-row surface-100">
      {/* <div className="hidden xl:flex bg-white">
        <SidebarMenu />
      </div> */}
      <div className="flex flex-column h-screen overflow-hidden flex-auto">
        <div>
          <Header />
        </div>
        <div className="overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default PrivateLayout;
