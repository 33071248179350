import exp from "constants";
import { del, get, post, put, getAddress } from "./api_helper";
import * as url from "./url_helper";

let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

// let config_upload_file = {
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// };

//user
export const login = (data: any) => post(url.LOGIN, data, config);
export const logout = () => post(url.LOGOUT, config);
export const register = (data: any) => post(url.REGISTER, data, config);
export const getAllUser = (query: any) =>
  get(url.GET_ALL_USER, { params: query, ...config });

//projects
export const getProjectDataAll = (query: any) =>
  get(url.GET_ALL_PROJECT, { params: query, ...config });
export const createProject = (data: any) =>
  post(url.CREATE_PROJECT, data, config);
export const updateDataProject = (id: string, data: any) =>
  put(`${url.UPDATE_PROJECT}/${id}`, data, config);
export const deleteDataProject = (id: any) =>
  del(`${url.DELETE_PROJECT}/${id}`, config);

// steps
export const getStepsDataById = (id: any) =>
  get(`${url.GET_ALL_STEPS}/${id}`, config);
export const createSteps = (data: any) =>
  post(url.CREATE_PROJECT, data, config);
export const updateDataSteps = (data: any) =>
  put(url.UPDATE_STEPS, data, config);
export const updateDataConfirm = (data: any) =>
  put(url.UPDATE_CONFIRM, data, config);
export const updateDataStart = (data: any) =>
  put(url.UPDATE_START, data, config);
export const deleteDataSteps = (id: any) =>
  del(`${url.DELETE_STEPS}/${id}`, config);

//work-task
export const getWorkTaskDataAll = (
  projectId: string,
  stepsId: string,
  query: any
) =>
  get(`${url.GET_ALL_WORK_TASKS}/${projectId}/${stepsId}`, {
    params: query,
    ...config,
  });

export const createWorkList = (data: any) =>
  post(url.CREATE_WORK_TASKS, data, config);
export const updateDataWorkTask = (id: string, data: any) =>
  put(`${url.UPDATE_WORK_TASKS}/${id}`, data, config);

//titles
export const getTitlesData = (workTaskId: string, stepName: string) =>
  get(
    `${url.GET_ALL_TITLE}/${workTaskId}?step_name=${encodeURIComponent(
      stepName
    )}`,
    config
  );

// export const getTitlesData = (id: any) =>
//   get(`${url.GET_ALL_TITLE}/${id}`, config);
export const createTitles = (data: any) => post(url.CREATE_TITLE, data, config);
// export const updateDataTitles = (data: any) =>
//   put(url.UPDATE_TITLE, data, config);
export const deleteDataTitles = (id: any) =>
  del(`${url.DELETE_TITLE}/${id}`, config);
export const updateDataTitles = (id: string, data: any) =>
  put(`${url.UPDATE_TITLE}/${id}`, data, config);


//tasks
export const getTasksDataById = (id: any) =>
  get(`${url.GET_ALL_TASKS}/${id}`, config);
export const createTasks = (data: any) => post(url.CREATE_TASKS, data, config);
export const updateDataTasks = (id: string, data: any) =>
  put(`${url.UPDATE_TASKS}/${id}`, data, config);
export const deleteDataTasks = (id: any) =>
  del(`${url.DELETE_TASKS}/${id}`, config);
export const setDeadline = (id: string, data: any) =>
  put(`${url.SET_DEADLINE}/${id}`, data, config);

// notes
export const getNotesDataById = (id: any) =>
  get(`${url.GET_ALL_NOTES}/${id}`, config);
export const createNotes = (data: any) => post(url.CREATE_NOTES, data, config);
export const updateDataNotes = (id: string, data: any) =>
  put(`${url.UPDATE_NOTES}/${id}`, data, config);
export const deleteDataNotes = (id: any) =>
  del(`${url.DELETE_NOTES}/${id}`, config);

//comment
export const getCommentDataById = (id: any) =>
  get(`${url.GET_ALL_COMMENT}/${id}`, config);
export const createComment = (data: any) =>
  post(url.CREATE_COMMENT, data, config);
export const updateDataComment = (id: string, data: any) =>
  put(`${url.UPDATE_COMMENT}/${id}`, data, config);
export const deleteDataComment = (id: any) =>
  del(`${url.DELETE_COMMENT}/${id}`, config);
