import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

interface ModelDeleteProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  accept: () => void;
  msg: React.ReactNode;
}
const ModelDelete: React.FC<ModelDeleteProps> = ({
  visible,
  setVisible,
  accept,
  msg,
}) => {
  return (
    <>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        header="Xác nhận"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={() => setVisible(false)}
        message={msg}
        acceptLabel="Đồng ý"
        rejectLabel="Hủy"
      />
    </>
  );
};

export default ModelDelete;
