import React from "react";

import Header from "./Header";

interface PrivateLayoutProps {
  children: React.ReactNode;
}

const PublicLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
  return (
    <div className="h-screen w-screen surface-100">
      <div>
        <Header />
      </div>
      <div className="flex flex-row justify-content-center mt-2">
        {children}
      </div>
    </div>
  );
};

export default PublicLayout;
