import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NotesState {
  notesData: any | null;
  error: string | null;
  loading: boolean;
  insertData: any | null;
  updateData: any | null;
  deleteData: any | null;
  errorInsert: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
}

const initialState: NotesState = {
  notesData: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const Noteslice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    getNotesDataAll: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNotesDataAllSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.notesData = action.payload;
      state.error = null;
    },
    getNotesDataAllFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.notesData = null;
      state.error = action.payload;
    },
    addNotes: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addNotesSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addNotesFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateNotes: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.updateData = null;
      state.errorUpdate = null;
    },
    updateNotesSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateData = action.payload;
      state.errorUpdate = null;
    },
    updateNotesFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorUpdate = action.payload;
      state.updateData = null;
    },
    deleteNotes: (state) => {
      state.loading = true;
    },
    deleteNotesSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteNotesFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
    },
  },
});

export const {
  getNotesDataAll,
  getNotesDataAllSuccess,
  getNotesDataAllFail,
  addNotes,
  addNotesSuccess,
  addNotesFail,
  updateNotes,
  updateNotesFail,
  updateNotesSuccess,
  deleteNotes,
  deleteNotesSuccess,
  deleteNotesFail,
  setDataNull,
} = Noteslice.actions;

export default Noteslice.reducer;
