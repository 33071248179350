import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { ProgressBar } from "primereact/progressbar";
import moment from "moment";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDataAll, setDataNull } from "../../../store/steps/slice";
import { StepStatus } from "../../../components/Interface";
import { setDataNull as setDataNullTitle } from "../../../store/titles/slice";

const TimelineTask = () => {
  const {
    setSelectedStepsId,
    selectedStepsId,
    setIsNextSteps,
    selectedProjectId,
    setSelectedStatus,
    selectedStatus,
    toggleIsLoad,
  } = useContext(ProjectContext);

  // work-group
  const {
    stepsData,
    updateDataTask,
    deleteData,
    updateConfirm,
    updateStepsStart,
    insertDataTask,
    // updateTitlesData
  } = useSelector(
    (state: any) => ({
      stepsData: state.Steps.stepsData,
      updateDataTask: state.Tasks.updateData,
      deleteData: state.Tasks.deleteData,
      updateConfirm: state.Steps.updateConfirm,
      errorUpdateConfirm: state.Steps.errorUpdateConfirm,
      errorUpdateStart: state.Steps.errorUpdateStart,
      updateStepsStart: state.Steps.updateStart,
      insertDataTask: state.Tasks.insertData,
      // updateTitlesData: state.Titles.insertData,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [selectedColor, setSelectedColor] = useState<string>("#6bd181"); // chọn màu khi chọn active
  const [selectedName, setSelectedName] = useState<string>("Tiếp nhận yêu cầu");
  const [temp, setTemp] = useState("");

  useEffect(() => {
    if (selectedProjectId) {
      dispatch(getDataAll(selectedProjectId?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  useEffect(() => {
    if (
      selectedProjectId &&
      (updateDataTask ||
        deleteData ||
        updateConfirm ||
        updateStepsStart ||
        insertDataTask)
    ) {
      dispatch(getDataAll(selectedProjectId?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProjectId,
    updateDataTask,
    deleteData,
    updateConfirm,
    updateStepsStart,
    insertDataTask,
    // updateTitlesData,
  ]);

  const handleItemClick = (type: any) => {
    setSelectedName(type?.name);
    setSelectedColor("#6bd181");
    setSelectedStatus(type?.status);
    setTemp(type?.id);
    if (
      type?.name === "Thiết kế giao diện" ||
      type?.name === "Phát triển & Kiểm thử"
    ) {
      setIsNextSteps(true);
      setSelectedStepsId(type?.id);
    } else {
      setIsNextSteps(false);
      setSelectedStepsId(type?.id);
    }
    if (temp !== type?.id) {
      dispatch(setDataNull());
      dispatch(setDataNullTitle());
    }
    toggleIsLoad();
  };

  useEffect(() => {
    if (stepsData) {
      const status = stepsData?.result?.steps.find(
        (e: any) => e.id === selectedStepsId
      )?.status;
      setSelectedStatus(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsData]);
  // Tính toán giá trị của timeline process
  const calculateCompletionPercent = (
    completedTasks: number,
    totalTasks: number
  ): number => {
    // Nếu tổng số task là 0, trả về 0
    if (totalTasks === 0) {
      return 0;
    }

    return (completedTasks / totalTasks) * 100;
  };

  const getTaskStatus = (task: any) => {
    if (task.name === selectedName) {
      if (selectedStatus === StepStatus.PENDING) {
        return {
          statusClass: "on-click-steps-1 on-click-status1",
          color: selectedColor,
        };
      } else if (selectedStatus === StepStatus.DOING) {
        return {
          statusClass: "on-click-steps-2 on-click-status2",
          color: selectedColor,
        };
      } else {
        return {
          statusClass: "on-click-steps-3 on-click-status3",
          color: selectedColor,
        };
      }
    }

    if (task.status === StepStatus.PENDING) {
      return { statusClass: "not-started", color: "#FFFFFF" };
    } else if (task.status === StepStatus.DOING) {
      return { statusClass: "no-complete", color: "#FFFFFF" };
    }
    return { statusClass: "complete-done", color: "#FFFFFF" };
  };

  const getTaskColor = (task: any) => getTaskStatus(task).color;

  const getTaskStatusClass = (task: any) => getTaskStatus(task).statusClass;

  // gán giá trị ban đầu khi đầu vào trang
  useEffect(() => {
    if (stepsData && stepsData?.result?.steps.length > 0 && !selectedStepsId) {
      const stepId = stepsData?.result?.steps.find(
        (items: any) => items.name === "Tiếp nhận yêu cầu"
      )?.id;

      const stepStatus = stepsData?.result?.steps.find(
        (items: any) => items.name === "Tiếp nhận yêu cầu"
      )?.status;

      setSelectedStatus(stepStatus);
      setSelectedStepsId(stepId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStepsId, stepsData]);

  return (
    <div className="card w-full">
      <ul className="timeline" id="timeline" style={{ width: "100%" }}>
        {stepsData &&
          stepsData?.result?.steps.map((item: any, index: number) => {
            let percent = calculateCompletionPercent(item.min, item.max);
            return (
              <li className="li complete" key={index}>
                <div className="timestamp">
                  <span className="date font-medium">
                    {moment(item.time).isValid() ? (
                      moment(item.time).format("DD-MM-YYYY")
                    ) : (
                      <span
                        className="empty-space"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "1rem",
                          opacity: 0,
                        }}
                      ></span>
                    )}
                  </span>
                </div>
                <div className={getTaskStatusClass(item)}></div>

                {/* Chỉ thêm today-marker sau phần tử đầu tiên */}
                {/* {index === 0 && (
                  <div className="today-marker">
                    <div className="dot"></div>
                    <div className="today-box">Hôm nay</div>
                  </div>
                )} */}

                <div
                  className="text-box"
                  style={{
                    backgroundColor: getTaskColor(item),
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                  onClick={() => handleItemClick(item)}
                >
                  <div
                    className="arrow-indicator"
                    style={{
                      borderBottom: `15px solid ${getTaskColor(item)}`,
                      cursor: "pointer",
                    }}
                  ></div>
                  <div className="flex align-items-center justify-content-between w-full">
                    <h5 className="font-normal" style={{ fontSize: "16px" }}>
                      {item?.name}
                    </h5>
                    <i className="pi pi-chevron-right ml-2"></i>
                  </div>
                  {item.max !== 0 && (
                    <div className="flex items-center justify-center align-items-center">
                      <ProgressBar
                        value={percent}
                        color="#278502"
                        className="custom-progress-bar mt-3"
                        showValue={false}
                      ></ProgressBar>
                      <span className="ml-2 mt-3 ">
                        {item.min}/{item.max}
                      </span>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default TimelineTask;
