import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { getDataAll } from "../../../store/project/slice";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import ActionTable from "../../../components/ActionTable";
import ModelDelete from "../../../components/ModalDelete";
import { deleteProject, setDataNull } from "../../../store/project/slice";
import ModalEditProject from "./ModalEditProject";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

export default function ListProject() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState<any>(null);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const { projectData, updateData, deleteData, errorDelete, insertData } =
    useSelector(
      (state: any) => ({
        projectData: state.Project.projectData,
        updateData: state.Project.updateData,
        deleteData: state.Project.deleteData,
        insertData: state.Project.insertData,
        errorDelete: state.Project.errorDelete,
      }),
      shallowEqual
    );

  // Lấy dữ liệu dự án ban đầu
  useEffect(() => {
    dispatch(getDataAll({ page: (first + rows) / rows, limit: rows }));
  }, [dispatch, first, rows]);

  useEffect(() => {
    if (updateData || deleteData || insertData) {
      dispatch(getDataAll({ page: (first + rows) / rows, limit: rows }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateData, deleteData, insertData, first, rows]);

  useEffect(() => {
    setDataTable(projectData?.result?.items);
  }, [projectData]);

  //sửa dữ liệu
  const handleEdit = (rowData: any) => {
    setModalEdit(true);
    setRowData(rowData);
  };

  //xóa dữ liệu
  const handleDelete = (rowData: any) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deleteProject(id));
      }
      setModalDelete(false);
    }
  };

  useEffect(() => {
    if (deleteData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Xóa dự án thành công",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (errorDelete) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Xóa dự án thất bại",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData, errorDelete]);
  const handleRowClick = (rowData: any) => {
    navigate("/", { state: { project: rowData } });
  };

  //Menu hành động
  const actionBodyTeamplate = (rowData: any) => {
    return (
      <ActionTable
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
      />
    );
  };

  return (
    <div className="dashboard-container bg-white border-round-md shadow-1 p-3 m-3">
      <Toast ref={toast} />
      <DataTable
        value={dataTable}
        emptyMessage="Không có dữ liệu"
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        size="small"
        onRowClick={(e: any) => handleRowClick(e.data)}
        rowClassName={() => "cursor-pointer"}
      >
        <Column
          header="STT"
          body={(rowData, options) => (
            <div className="text-center">{first + options.rowIndex + 1}</div>
          )}
          style={{ width: "3%", minWidth: "60px" }}
          alignHeader={"center"}
          align={"center"}
          frozen
        ></Column>
        <Column header="Tên dự án" field="name" style={{ minWidth: "180px" }} />
        <Column
          header="Tên khách hàng"
          field="cusName"
          style={{ minWidth: "180px" }}
        />
        <Column
          header="Mã khách hàng"
          field="cusCode"
          style={{ minWidth: "160px" }}
        />
        <Column header="Email" field="cusEmail" style={{ minWidth: "180px" }} />
        <Column header="SĐT" field="cusPhone" />
        <Column body={actionBodyTeamplate} />
      </DataTable>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={projectData?.result?.totalItems}
        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
        onPageChange={onPageChange}
      />
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa dự án này?
            <br />
            Các thông tin liên quan đến dự án sẽ bị xóa.
          </div>
        }
      />
      <ModalEditProject
        visible={modalEdit}
        onHide={() => setModalEdit(false)}
        rowData={rowData}
      />
    </div>
  );
}
