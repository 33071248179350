import React, { useEffect, useRef, useState, useContext } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Link } from "react-router-dom";
import { register, setDataAuthNull } from "../../store/auth/slice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

export default function ModalAddUser({
    visible,
    onHide,
}: {
    visible: boolean;
    onHide: () => void;
}) {
    const dispatch = useDispatch();
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();

    const { dataRegister, errorRegister } = useSelector(
        (state: any) => ({
            dataRegister: state.Auth.dataRegister,
            errorRegister: state.Auth.errorRegister,
        }),
        shallowEqual
    );
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            password: "",
            confirm_password: "",
            name: "",
            role: "dev",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .required("Vui lòng nhập địa chỉ email")
                .email("Email không hợp lệ"),
            password: Yup.string().required("Mật khẩu không được để trống"),
            confirm_password: Yup.string()
                .required("Xác nhận mật khẩu không được để trống")
                .oneOf(
                    [Yup.ref("password")],
                    "Mật khẩu và xác nhận mật khẩu không khớp"
                ),
            name: Yup.string().required("Vui lòng nhập họ & tên"),
        }),
        onSubmit: (values: any) => {
            dispatch(register(values));
        },
    });

    useEffect(() => {
        if (dataRegister) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Đăng ký thành công",
                life: 3000,
            });
            onHide();
            dispatch(setDataAuthNull());
        }
        if (errorRegister) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: `Đăng ký thất bại`,
                life: 3000,
            });
            dispatch(setDataAuthNull());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRegister, errorRegister, navigate, dispatch]);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">
                Thêm mới nhân viên
            </span>
        </div>
    );

    return (
        <div className="card p-5 w-12 md:w-6 lg:w-3">
            <Toast ref={toast} />
            <Dialog
                visible={visible}
                onHide={() => onHide()}
                header={headerElement}
                className="w-10 md:w-4"
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="card bg-white p-3 border-round-xl">
                        <div className="flex flex-column mb-4">
                            <label htmlFor="email" className="mb-2">
                                Họ & tên
                            </label>
                            <InputText
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id="name"
                                name="name"
                                autoComplete="on"
                                type="text"
                                placeholder="Họ & tên"
                                className="w-full p-3"
                                invalid={
                                    formik.touched.name && formik.errors.name
                                        ? true
                                        : false
                                }
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: "5px",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {String(formik.errors.name)}
                                </p>
                            ) : null}
                        </div>
                        <div className="flex flex-column mb-4">
                            <label htmlFor="email" className="mb-2">
                                Email
                            </label>
                            <InputText
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id="email"
                                name="email"
                                autoComplete="on"
                                type="text"
                                placeholder="Email"
                                className="w-full p-3"
                                invalid={
                                    formik.touched.email && formik.errors.email
                                        ? true
                                        : false
                                }
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: "5px",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {String(formik.errors.email)}
                                </p>
                            ) : null}
                        </div>
                        <div className="flex flex-column mb-4">
                            <label className="mb-2">Mật khẩu</label>
                            <Password
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Mật khẩu"
                                autoComplete="on"
                                inputClassName="w-full p-3"
                                pt={{
                                    iconField: {
                                        root: { className: "w-full" },
                                    },
                                }}
                                toggleMask
                                feedback={false}
                                invalid={
                                    formik.touched.password &&
                                    formik.errors.password
                                        ? true
                                        : false
                                }
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: "5px",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {String(formik.errors.password)}
                                </p>
                            ) : null}
                        </div>
                        <div className="flex flex-column mb-4">
                            <label className="mb-2">Xác nhận mật khẩu</label>
                            <Password
                                value={formik.values.confirm_password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id="confirm_password"
                                name="confirm_password"
                                type="password"
                                placeholder="Xác nhận mật khẩu"
                                autoComplete="on"
                                inputClassName="w-full p-3"
                                pt={{
                                    iconField: {
                                        root: { className: "w-full" },
                                    },
                                }}
                                toggleMask
                                feedback={false}
                                invalid={
                                    formik.touched.confirm_password &&
                                    formik.errors.confirm_password
                                        ? true
                                        : false
                                }
                            />
                            {formik.touched.password &&
                            formik.errors.confirm_password ? (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: "5px",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {String(formik.errors.confirm_password)}
                                </p>
                            ) : null}
                        </div>
                        {/* <div className="flex align-items-center justify-content-between mb-5 gap-5">
                            <div className="flex align-items-center">
                                <Checkbox
                                    inputId="rememberLogin"
                                    checked={checked}
                                    onChange={(e: any) => setChecked(e.checked)}
                                    className="mr-2"
                                ></Checkbox>
                                <label htmlFor="rememberLogin" className="text-sm">
                                    Lưu mật khẩu
                                </label>
                            </div>
                            <Link
                                to={`/verify-email?type=forget-password`}
                                className="font-medium text-sm no-underline text-right cursor-pointer"
                                style={{ color: "var(--primary-color)" }}
                            >
                                Quên mật khẩu?
                            </Link>
                        </div> */}
                        <Button
                            type="submit"
                            label="Thêm mới"
                            className="w-full my-5"
                            // onClick={handleButtonClick}
                        />
                    </div>
                </form>
            </Dialog>
        </div>
    );
}
