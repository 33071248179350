import { useRef, useEffect } from "react";

import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";

// import { active, setDataNull } from '../../store/auth/slice.js';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VerifyEmail() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  let type = query.get("type");

  // const { dataActive, errorActive } = useSelector(
  //   (state) => ({
  //     dataActive: state.Auth.dataActive,
  //     errorActive: state.Auth.errorActive,
  //   }),
  //   shallowEqual
  // );

  // use formik to handle form
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email không hợp lệ")
        .required("Email không được để trống"),
    }),
    onSubmit: async (values) => {
      // if (type === 'forget-password') {
      //   dispatch(active({ ...values, resend: true }));
      // } else dispatch(active(values));
    },
  });

  // useEffect(() => {
  //   if (errorActive) {
  //     toast.current?.show({
  //       severity: 'error',
  //       summary: 'Thất bại',
  //       detail: errorActive.message,
  //       life: 3000,
  //     });
  //   }
  // }, [errorActive]);

  // useEffect(() => {
  //   if (dataActive) {
  //     toast.current?.show({
  //       severity: 'success',
  //       summary: 'Thành công',
  //       detail: 'Kiểm tra email của bạn để nhận mã xác minh.',
  //       life: 3000,
  //     });
  //     setTimeout(() => {
  //       navigate(`/otp?email=${formik.values.email}`);
  //       dispatch(setDataNull());
  //     }, 1000);
  //   }
  // }, [dataActive]);

  return (
    <div className="card p-5" style={{ width: "450px", height: "65vh" }}>
      <form>
        <Toast ref={toast} />
        <div className="bg-white card p-3 border-round-xl ">
          <div>
            <div className="text-center p-5 ">
              <h4 className="text-3xl">Xác minh email</h4>
            </div>
            <div className="flex flex-column ">
              <label className="mb-3">Nhập Email</label>
              <InputText
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="email"
                name="email"
                type="text"
                placeholder="email_example@mail.com"
                className="w-full  p-3"
                invalid={
                  formik.touched.email && formik.errors.email ? true : false
                }
              />
              {formik.touched.email && formik.errors.email ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.email)}
                </p>
              ) : null}
            </div>
            <Button
              type="submit"
              label="Tiếp tục"
              className="w-full my-5"
              // onClick={formik.handleSubmit}
            />
          </div>
          <div className="font-medium text-sm flex align-items-center justify-content-center pt-5 gap-2">
            Trở về trang
            <Link
              to={"/login"}
              className="font-medium text-sm no-underline text-right cursor-pointer"
              style={{ color: "var(--primary-color)" }}
            >
              Đăng nhập
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default VerifyEmail;
