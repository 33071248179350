import React from "react";
import SelectLanguage from "../../components/SelectLanguage";
import CircleImage from "../../components/CircleImage";
import { Avatar } from "primereact/avatar";
import { Image } from "primereact/image";

function Header() {
  return (
    <div className="bg-white flex justify-content-center p-2">
      <div
        className="flex flex-row justify-content-center"
        style={{ width: "400px" }}
      >
        <div className="flex align-items-center justify-content-between ml-2 w-full h-4rem">
          {/* <Avatar
            image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
            shape="circle"
            // size="large"
          /> */}
          <Image src="/images/logo/logo-company.jpg" alt="Image" width="50" />
          <span className="ml-3 font-bold text-primary">Quản lý dự án</span>
        </div>
      </div>
    </div>
  );
}

export default Header;
