// import { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routes";
import { privateRoutes } from "./routes";
import PrivateLayout from "./layout/PrivateLayout";
import PublicLayout from "./layout/PublicLayout";
import Authmiddleware from "./routes/authMiddleware";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/scss/themes/main/green/theme.scss";

// import io from "socket.io-client";
// import { site } from "./api/url_helper";
// import { DataContext } from "./contexts/data/DataProvider";

function App() {
  // const {
  //   message,
  //   setMessage,
  //   skTicket,
  //   setSkTicket,
  //   setSocket,
  //   setUpdateUserCount,
  //   notification,
  // } = useContext(DataContext);

  // useEffect(() => {
  //   const newSocket = io(site, {
  //     secure: true,
  //     rejectUnauthorized: false, // Chỉ cần đặt rejectUnauthorized là false nếu bạn sử dụng chứng chỉ tự ký
  //     path: "/socket.io",
  //   });

  //   setSocket(newSocket);
  //   newSocket.on("updateUserCount", (mes) => {
  //     setUpdateUserCount(mes);
  //   });

  //   newSocket.on("message", (mes) => {
  //     setMessage(mes);
  //   });

  //   newSocket.on("new ticket", (mes) => {
  //     setSkTicket(mes);
  //   });

  //   return () => {
  //     newSocket.disconnect();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [message, skTicket, notification]);

  return (
    <BrowserRouter>
      <div>
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PublicLayout>
                    <Page />
                  </PublicLayout>
                }
              />
            );
          })}
          {privateRoutes.map((route, index) => {
            const Page = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Authmiddleware path={route.path}>
                    <PrivateLayout>
                      <Page />
                    </PrivateLayout>
                  </Authmiddleware>
                }
              />
            );
          })}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
