import React, { createContext, ReactNode, Dispatch, useState } from "react";

type ChildContainerProps = {
  children: ReactNode;
};

export interface ProjectProps {
  visibleModalProject: boolean;
  setVisibleModalProject: Dispatch<React.SetStateAction<boolean>>;
  selectedProjectId: any;
  setSelectedProjectId: Dispatch<React.SetStateAction<any>>;
  selectedStepsId: string;
  setSelectedStepsId: Dispatch<React.SetStateAction<string>>;
  selectedWorkTaskId: string;
  setSelectedWorkTaskId: Dispatch<React.SetStateAction<string>>;
  isLoad: boolean;
  setIsLoad: Dispatch<React.SetStateAction<boolean>>;
  isSpending: boolean;
  setIsSpending: Dispatch<React.SetStateAction<boolean>>;
  toggleIsSpending: () => void; // Thêm hàm để toggle isSpending
  toggleIsLoad: () => void;
  selectedTaskId: string;
  setSelectedTaskId: Dispatch<React.SetStateAction<string>>;
  isLoadProject: boolean;
  setIsLoadProject: Dispatch<React.SetStateAction<boolean>>;
  toggleSelectedProject: () => void;
  selectedTitleTask: string; // lấy tên khi click vào xem chi tiết
  setSelectedTitleTask: Dispatch<React.SetStateAction<string>>;
  isAddTaskAndGroup: boolean;
  setIsAddTaskAndGroup: Dispatch<React.SetStateAction<boolean>>;
  toggleIsAddTaskAndGroup: () => void;
  isNextSteps: boolean;
  setIsNextSteps: Dispatch<React.SetStateAction<boolean>>;
  selectedEditTask: any;
  setSelectedEditTask: Dispatch<React.SetStateAction<any>>;
  checkNoteEdit: boolean;
  setCheckNoteEdit: Dispatch<React.SetStateAction<boolean>>;
  selectedStatus: string; // lấy tên khi click vào xem chi tiết
  setSelectedStatus: Dispatch<React.SetStateAction<string>>;
  selectNameProject: string;
  setSelectNameProject: Dispatch<React.SetStateAction<string>>;
  roleName: string;
  setRoleName: Dispatch<React.SetStateAction<string>>;
}

export const ProjectContext = createContext({} as ProjectProps);

export const ProjectProvider = ({ children }: ChildContainerProps) => {
  const [visibleModalProject, setVisibleModalProject] =
    useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<any>("");
  const [selectedStepsId, setSelectedStepsId] = useState<string>("");
  const [selectedWorkTaskId, setSelectedWorkTaskId] = useState<string>("");
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isSpending, setIsSpending] = useState<boolean>(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string>("");
  const [isLoadProject, setIsLoadProject] = useState<boolean>(false);
  const [selectedTitleTask, setSelectedTitleTask] = useState<string>("");
  const [isAddTaskAndGroup, setIsAddTaskAndGroup] = useState<boolean>(true);
  const [isNextSteps, setIsNextSteps] = useState<boolean>(false);
  const [selectedEditTask, setSelectedEditTask] = useState<any>("");
  const [checkNoteEdit, setCheckNoteEdit] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectNameProject, setSelectNameProject] = useState<string>("");
  const [roleName, setRoleName] = useState<string>("");

  // Hàm để toggle trạng thái isSpending
  const toggleIsSpending = () => {
    setIsSpending((prevState) => !prevState);
  };

  const toggleIsLoad = () => {
    setIsLoad((prevState) => !prevState);
  };

  const toggleSelectedProject = () => {
    setIsLoadProject((prevState) => !prevState);
  };

  const toggleIsAddTaskAndGroup = () => {
    setIsAddTaskAndGroup((prevState) => !prevState);
  };

  const value = {
    visibleModalProject,
    setVisibleModalProject,
    selectedProjectId,
    setSelectedProjectId,
    selectedStepsId,
    setSelectedStepsId,
    selectedWorkTaskId,
    setSelectedWorkTaskId,
    isLoad,
    setIsLoad,
    isSpending,
    setIsSpending,
    toggleIsSpending,
    toggleIsLoad,
    selectedTaskId,
    setSelectedTaskId,
    isLoadProject,
    setIsLoadProject,
    toggleSelectedProject,
    selectedTitleTask,
    setSelectedTitleTask,
    isAddTaskAndGroup,
    setIsAddTaskAndGroup,
    toggleIsAddTaskAndGroup,
    isNextSteps,
    setIsNextSteps,
    selectedEditTask,
    setSelectedEditTask,
    checkNoteEdit,
    setCheckNoteEdit,
    selectedStatus,
    setSelectedStatus,
    selectNameProject,
    setSelectNameProject,
    roleName,
    setRoleName,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};
