import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CommentState {
  CommentData: any | null;
  error: string | null;
  loading: boolean;
  insertData: any | null;
  updateData: any | null;
  deleteData: any | null;
  errorInsert: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
}

const initialState: CommentState = {
  CommentData: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const CommentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    getCommentDataAll: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getCommentDataAllSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.CommentData = action.payload;
      state.error = null;
    },
    getCommentDataAllFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.CommentData = null;
      state.error = action.payload;
    },
    addComment: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addCommentSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addCommentFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateComment: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.updateData = null;
      state.errorUpdate = null;
    },
    updateCommentSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.CommentData = action.payload;
      state.errorUpdate = null;
    },
    updateCommentFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorUpdate = action.payload;
      state.CommentData = null;
    },
    deleteComment: (state) => {
      state.loading = true;
    },
    deleteCommentSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteCommentFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.CommentData = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
    },
  },
});

export const {
  getCommentDataAll,
  getCommentDataAllSuccess,
  getCommentDataAllFail,
  addComment,
  addCommentSuccess,
  addCommentFail,
  updateComment,
  updateCommentFail,
  updateCommentSuccess,
  deleteComment,
  deleteCommentSuccess,
  deleteCommentFail,
  setDataNull,
} = CommentSlice.actions;

export default CommentSlice.reducer;
