import Dashboard from "../pages/Dashboard";
import Login from "../pages/Authentication/Login";
import VerifyEmail from "../pages/Authentication/VerifyEmail";
import ResetPassword from "../pages/Authentication/ResetPassword";
import VerifyLink from "../pages/Authentication/VerifyLink";
import ListProject from "../pages/Dashboard/Project/ListProject";
import Register from "../pages/Authentication/Register";
import User from "../pages/User/User";

// import Login from "../pages/Authentication/Login";
// import License from "../pages/Authentication/License";
// import Register from "../pages/Authentication/Register";
// import ForgetPassword from "../pages/Authentication/ForgetPassword";

//Public Routes
const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forget-password", component: ResetPassword },
  { path: "/verify-email", component: VerifyEmail },
  { path: "/verify-link", component: VerifyLink },
];

//Private Routes
const privateRoutes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  { path: "/dashboard", component: Dashboard },
  { path: "/list-project", component: ListProject },
  { path: "/user", component: User },
];

export { publicRoutes, privateRoutes };
