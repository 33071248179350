import React, { useRef, useState, useContext, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Avatar } from "primereact/avatar";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addNotes } from "../../../store/notes/slice";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { Image } from "primereact/image";

export default function ModelNoteTask({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: any;
}) {
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();
  const { selectedWorkTaskId, toggleIsLoad, selectedTitleTask, roleName } =
    useContext(ProjectContext);
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      fileUrl: string;
      fileName: string;
      fileSize: string;
      iconUrl: string;
      file: File;
    }[]
  >([]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap"> {selectedTitleTask}</span>
    </div>
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => {
        const fileUrl = URL.createObjectURL(file);
        let iconUrl = "";

        // Determine icon based on file extension
        if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
          iconUrl = "/images/image/excel.png";
        } else if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
          iconUrl = "/images/image/word.png";
        } else if (
          file.name.endsWith(".jpg") ||
          file.name.endsWith(".jpeg") ||
          file.name.endsWith(".png") ||
          file.name.endsWith(".gif") ||
          file.name.endsWith(".bmp")
        ) {
          iconUrl = fileUrl; // Use the fileUrl itself as the icon for image files
        } else {
          iconUrl = "/images/image/default.png"; // Optional: Default icon for other file types
        }

        return {
          fileUrl,
          fileName: file.name,
          fileSize: `${(file.size / (1024 * 1024)).toFixed(2)}\u00A0MB`, // Use a non-breaking space
          iconUrl,
          file, // Save the file object itself
        };
      });
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  //work-group
  const { insertData, errorInsert } = useSelector(
    (state: any) => ({
      insertData: state.Notes.insertData,
      errorInsert: state.Notes.errorInsert,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (insertData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Tạo ghi chú thành công",
        life: 3000,
      });
      onClose();
      toggleIsLoad();
      formik.resetForm();
      setUploadedFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertData]);

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Tạo ghi chú thất bại`,
        life: 3000,
      });
    }
  }, [errorInsert]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "123",
      description: "",
      workTaskId: selectedWorkTaskId,
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required("Ghi chú không được để trống"),
    }),
    onSubmit: (values: any) => {
      dispatch(
        addNotes({
          ...values,
          uploadedFiles, // truyền thêm uploadedFiles vào
        })
      );
    },
  });

  const footerContent = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {roleName && (
        <>
          <div className="button-container">
            <input
              type="file"
              name="files"
              id="upload"
              hidden
              accept=".xlsx,.xls,.doc,.docx,.jpg,.jpeg,.png,.gif"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="upload" className="upload-file">
              <i className="pi pi-paperclip"></i> Đính kèm
            </label>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="custom-button"
              onClick={() => {
                onClose();
                setUploadedFiles([]);
                formik.resetForm();
              }}
              style={{ backgroundColor: "#808080", color: "#ffffff" }}
            >
              Huỷ
            </button>
            <button
              className="custom-button"
              onClick={() => formik.handleSubmit()}
            >
              Lưu
            </button>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          onClose();
          setUploadedFiles([]);
          formik.resetForm();
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <label htmlFor="title" className="mb-2 font-semibold">
            Ghi chú
          </label>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="description"
            style={{ width: "100%" }}
            invalid={
              formik.touched.description && formik.errors.description
                ? true
                : false
            }
          />
          {formik.touched.description && formik.errors.description ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0", // Ensure it starts at the beginning of the line
                textAlign: "left", // Align text to the left
              }}
            >
              {String(formik.errors.description)}
            </p>
          ) : null}
        </div>
        {uploadedFiles.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between align-items-center h-5rem w-full"
          >
            <div className="flex items-center w-full">
              <Image
                className="p-overlay-badge"
                src={item.iconUrl || ""} // Nếu không có imageUrl, sử dụng defaultImage
                width="40"
                height="40"
                preview
              />
              <p className="font-semibold text-grey-300 mt-3 ml-3">
                {item.fileName}
              </p>
            </div>
            <p className="text-grey-300">{item.fileSize}</p>
          </div>
        ))}
      </Dialog>
    </div>
  );
}
