import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { getAllUserFail, getAllUserSuccess } from "./slice";
import { getAllUser } from "../../api/backend_helper";

function* GetUserSaga(action: PayloadAction<any>): Generator<any, void, any> {
    try {
        const data = yield call(getAllUser, action.payload);
        yield put(getAllUserSuccess(data));
    } catch (error) {
        yield put(getAllUserFail(error));
    }
}

function* UserSaga(): Generator<any, void, any> {
    yield takeLatest("user/getAllUser", GetUserSaga);
}

export default UserSaga;
