// export const site = "http://localhost:3201/api/v1";
// export const site_frontend = "http://localhost:3000";

export const site = "https://api.project.itomo.vn/api/v1";
export const site_frontend = "https://project.itomo.vn";

export const api_address = "https://address.itomo.vn";

//login
export const LOGIN = `${site}/auth/login`;
export const LOGOUT = `${site}/auth/logout`;
export const REGISTER = `${site}/auth/register`;

//user
export const GET_ALL_USER = `${site}/auth/get-all-user`;

// project
export const CREATE_PROJECT = `${site}/projects`;
export const GET_ALL_PROJECT = `${site}/projects`;
export const UPDATE_PROJECT = `${site}/projects`;
export const DELETE_PROJECT = `${site}/projects`;

// steps
export const CREATE_STEPS = `${site}/steps`;
export const GET_ALL_STEPS = `${site}/steps`;
export const UPDATE_STEPS = `${site}/steps`;
export const DELETE_STEPS = `${site}/steps`;
export const UPDATE_CONFIRM = `${site}/steps/confirm`;
export const UPDATE_START = `${site}/steps/start`;

// work-task
export const CREATE_WORK_TASKS = `${site}/work-tasks`;
export const GET_ALL_WORK_TASKS = `${site}/work-tasks`;
export const UPDATE_WORK_TASKS = `${site}/work-tasks`;
export const DELETE_WORK_TASKS = `${site}/work-tasks`;

//Title
export const CREATE_TITLE = `${site}/titles`;
export const GET_ALL_TITLE = `${site}/titles`;
export const UPDATE_TITLE = `${site}/titles`;
export const DELETE_TITLE = `${site}/titles`;

//Task
export const CREATE_TASKS = `${site}/tasks`;
export const GET_ALL_TASKS = `${site}/tasks`;
export const UPDATE_TASKS = `${site}/tasks`;
export const DELETE_TASKS = `${site}/tasks`;
export const SET_DEADLINE = `${site}/tasks/set-deadline`;

//Note
export const CREATE_NOTES = `${site}/notes`;
export const GET_ALL_NOTES = `${site}/notes`;
export const UPDATE_NOTES = `${site}/notes`;
export const DELETE_NOTES = `${site}/notes`;

// Comment
export const CREATE_COMMENT = `${site}/comments`;
export const GET_ALL_COMMENT = `${site}/comments`;
export const UPDATE_COMMENT = `${site}/comments`;
export const DELETE_COMMENT = `${site}/comments`;
