import React, { useState, useContext, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setDataNull, updateTasks } from "../../../store/tasks/slice";
import { updateNotes } from "../../../store/notes/slice";

function ModelListFileTask({ open, onClose }: { open: boolean; onClose: any }) {
  const { selectedTitleTask, checkNoteEdit, setCheckNoteEdit } =
    useContext(ProjectContext);
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      fileUrl: string;
      fileName: string;
      fileSize: string;
      iconUrl: string;
      file: File;
      isSelected: boolean;
    }[]
  >([]);
  const dispatch = useDispatch();
  const { selectedEditTask, roleName } = useContext(ProjectContext);
  const toast = useRef<Toast>(null);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">{selectedTitleTask}</span>
    </div>
  );
  const fileOpject = selectedFiles?.map((fileName) => ({ key: fileName }));

  const {
    listTaskData,
    updateDataTaskFile,
    errorUpdateTaskFile,
    listNotesData,
    updateNotesData,
    updateErrorNotes,
  } = useSelector(
    (state: any) => ({
      listTaskData: state.Tasks.tasksData,
      updateDataTaskFile: state.Tasks.updateData,
      errorUpdateTaskFile: state.Tasks.errorUpdate,
      listNotesData: state.Notes.notesData,
      updateNotesData: state.Notes.updateData,
      updateErrorNotes: state.Notes.errorUpdate,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (checkNoteEdit) {
      if (listNotesData && listNotesData.result.items && checkNoteEdit) {
        const filesArray = listNotesData.result.items
          .flatMap((item: any) => item.files) // Lấy mảng files từ mỗi item gom nó thành 1 mảng
          .filter(
            (file: any) =>
              file.key.endsWith(".xlsx") ||
              file.key.endsWith(".xls") ||
              file.key.endsWith(".doc") ||
              file.key.endsWith(".docx")
          )
          .map((file: any) => {
            const iconUrl =
              file.key.endsWith(".xlsx") || file.key.endsWith(".xls")
                ? "/images/image/excel.png"
                : file.key.endsWith(".doc") || file.key.endsWith(".docx")
                ? "/images/image/word.png"
                : "/images/image/default.png";

            return {
              fileUrl: file.value,
              fileName: file.key,
              fileSize: "Unknown",
              iconUrl,
              file: null,
            };
          });

        setUploadedFiles(filesArray);
      }
    } else {
      if (listTaskData && listTaskData.result && listTaskData.result.files) {
        const filesArray = listTaskData.result.files
          .filter(
            (file: any) =>
              file.key.endsWith(".xlsx") ||
              file.key.endsWith(".xls") ||
              file.key.endsWith(".doc") ||
              file.key.endsWith(".docx")
          )
          .map((file: any) => {
            const iconUrl =
              file.key.endsWith(".xlsx") || file.key.endsWith(".xls")
                ? "/images/image/excel.png"
                : file.key.endsWith(".doc") || file.key.endsWith(".docx")
                ? "/images/image/word.png"
                : "/images/image/default.png"; // Default icon for other file types

            return {
              fileUrl: file.value,
              fileName: file.key,
              fileSize: "Unknown",
              iconUrl,
              file: null,
            };
          });
        setUploadedFiles(filesArray);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTaskData, listNotesData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "upload",
    },
    onSubmit: (values) => {
      if (checkNoteEdit) {
        const dataToSend = {
          id: selectedEditTask?.id,
          description: selectedEditTask.description,
          title: selectedEditTask.title,
          files: uploadedFiles || [],
          files_remove: fileOpject || [],
        };
        dispatch(updateNotes(dataToSend));
        setUploadedFiles([]);
      } else {
        const dataToSend = {
          id: selectedEditTask?.id,
          isDones: selectedEditTask?.isDones,
          files: uploadedFiles || [],
          files_remove: fileOpject || [],
        };

        dispatch(updateTasks(dataToSend));
      }
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => {
        const fileUrl = URL.createObjectURL(file);
        let iconUrl = "";
        if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
          iconUrl = "/images/image/excel.png";
        } else if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
          iconUrl = "/images/image/word.png";
        } else {
          iconUrl = "/images/image/default.png";
        }

        return {
          fileUrl,
          fileName: file.name,
          fileSize: `${(file.size / (1024 * 1024)).toFixed(2)}\u00A0MB`, // Use a non-breaking space
          iconUrl,
          file, // Save the file object itself
          isSelected: false,
        };
      });
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const footerContent = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {roleName === "pm" && (
        <div className="button-container">
          <input
            type="file"
            name="files"
            id="upload"
            hidden
            accept=".xlsx,.xls,.doc,.docx"
            multiple
            onChange={handleFileChange}
          />

          <label htmlFor="upload" className="upload-file">
            <i className="pi pi-paperclip"></i> Đính kèm
          </label>
        </div>
      )}

      {roleName === "pm" && (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className="custom-button"
            onClick={() => {
              setSelectedFiles([]);
              setUploadedFiles([]);
              onClose();
              setCheckNoteEdit(false);
            }}
            style={{ backgroundColor: "#808080", color: "#ffffff" }}
          >
            Huỷ
          </button>
          <button
            className="custom-button"
            onClick={() => formik.handleSubmit()}
          >
            Lưu
          </button>
        </div>
      )}
    </div>
  );

  const handleDeleteToggle = (fileName: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.fileName === fileName
          ? { ...file, isSelected: !file.isSelected }
          : file
      )
    );

    setSelectedFiles((prevSelected) => {
      if (prevSelected.includes(fileName)) {
        return prevSelected.filter((name) => name !== fileName);
      } else {
        return [...prevSelected, fileName];
      }
    });
  };

  useEffect(() => {
    if (updateDataTaskFile) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Thành công",
        life: 3000,
      });
      dispatch(setDataNull());
      setUploadedFiles([]);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataTaskFile]);

  useEffect(() => {
    if (errorUpdateTaskFile) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Thất bại`,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdateTaskFile]);

  useEffect(() => {
    if (updateNotesData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Cập nhật thành công",
        life: 3000,
      });
      setUploadedFiles([]);
      dispatch(setDataNull());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNotesData]);

  useEffect(() => {
    if (updateErrorNotes) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Cập nhật thất bại`,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateErrorNotes]);

  return (
    <div className="card flex justify-content-center">
      <Dialog
        visible={open}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          onClose();
          setSelectedFiles([]);
          setUploadedFiles([]);
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div
          className="flex flex-wrap"
          style={{ gap: "1rem", justifyContent: "flex-start" }}
        >
          {uploadedFiles.length > 0 ? (
            uploadedFiles.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between align-items-center h-5rem w-full"
              >
                <div className="flex items-center w-full">
                  <Avatar
                    className="p-overlay-badge"
                    image={item.iconUrl}
                    size="large"
                  ></Avatar>
                  <p className="font-semibold text-grey-300 mt-3 ml-3">
                    {item.fileName}
                  </p>
                </div>
                <p className="text-grey-300">{item.fileSize}</p>
                <span
                  className={`delete-icon ${item.isSelected ? "selected" : ""}`}
                  onClick={() => handleDeleteToggle(item.fileName)}
                  style={{}}
                >
                  &times;
                </span>
              </div>
            ))
          ) : (
            <div
              className="flex align-items-start "
              style={{ flexBasis: "calc(20% - 1rem)", marginBottom: "1rem" }}
            >
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ddd",
                  backgroundColor: "#f0f0f0",
                  fontSize: "16px",
                  color: "#999",
                }}
              >
                No File
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default ModelListFileTask;
