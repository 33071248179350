import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StepsState {
  stepsData: any | null;
  error: string | null;
  loading: boolean;
  insertData: any | null;
  updateData: any | null;
  deleteData: any | null;
  errorInsert: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
  updateConfirm: string | null;
  errorUpdateConfirm: string | null;
  updateStart: string | null;
  errorUpdateStart: string | null;
}

const initialState: StepsState = {
  stepsData: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  updateConfirm: null,
  updateStart: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
  errorUpdateConfirm: null,
  errorUpdateStart: null,
};

const StepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    getDataAll: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getDataAllSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.stepsData = action.payload;
      state.error = null;
    },
    getDataAllFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.stepsData = null;
      state.error = action.payload;
    },
    addSteps: (state, action: PayloadAction<StepsState>) => {
      state.loading = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addStepsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addStepsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateSteps: (state) => {
      state.loading = true;
      // state.updateData = null;
      // state.errorUpdate = null;
    },
    updateStepsSuccess: (state, action) => {
      state.loading = false;
      const index = state.stepsData.findIndex(
        (item: any) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.stepsData[index] = action.payload;
      }
      state.error = null;
    },
    updateStepsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateDataConfirm: (state, action: PayloadAction<any>) => {
      state.loading = true;
      // state.updateConfirm = action.payload;
      state.errorUpdateConfirm = null;
    },
    updateConfirmSuccess: (state, action) => {
      state.loading = false;
      state.updateConfirm = action.payload;
      state.errorUpdateConfirm = null;
    },
    updateConfirmFail: (state, action) => {
      state.loading = false;
      state.errorUpdateConfirm = action.payload;
      state.updateConfirm = null;
    },
    updateDataStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
      // state.updateStart = action.payload;
      state.errorUpdateStart = null;
    },
    updateStartSuccess: (state, action) => {
      state.loading = false;
      state.updateStart = action.payload;
      state.errorUpdateStart = null;
    },
    updateStartFail: (state, action) => {
      state.loading = false;
      state.errorUpdateStart = action.payload;
    },
    deleteSteps: (state) => {
      state.loading = true;
    },
    deleteStepsSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteStepsFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      // state.stepsData = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
      state.errorUpdateConfirm = null;
      state.updateConfirm = null;
      state.updateStart = null;
    },
  },
});

export const {
  getDataAll,
  getDataAllSuccess,
  getDataAllFail,
  addSteps,
  addStepsSuccess,
  addStepsFail,
  updateSteps,
  updateStepsFail,
  updateStepsSuccess,
  deleteSteps,
  deleteStepsSuccess,
  deleteStepsFail,
  setDataNull,
  updateDataConfirm,
  updateConfirmFail,
  updateConfirmSuccess,
  updateDataStart,
  updateStartFail,
  updateStartSuccess,
} = StepsSlice.actions;

export default StepsSlice.reducer;
