import React, { useContext, useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { setDeadline } from "../../../store/tasks/slice";
import { Toast } from "primereact/toast";

export default function ModelTimesTask({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const { selectedTaskId, selectedEditTask, roleName } =
    useContext(ProjectContext);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const { setDeadlineData, setDeadlineError } = useSelector(
    (state: any) => ({
      setDeadlineData: state.Tasks.setDeadlineData,
      setDeadlineError: state.Tasks.setDeadlineError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selectedEditTask) {
      const { startDate, endDate } = selectedEditTask;
      setStartDate(startDate ? new Date(startDate) : null);
      setEndDate(endDate ? new Date(endDate) : null);
    }
  }, [selectedEditTask]);

  function formatDate(date: Date | null): string {
    if (date === null) {
      return "No date selected";
    }

    // Lấy năm, tháng và ngày từ đối tượng Date
    const year: number = date.getFullYear();
    const month: string = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
    const day: string = String(date.getDate()).padStart(2, "0");

    // Trả về định dạng yyyy/mm/dd
    return `${year}-${month}-${day}`;
  }

  const handleSave = () => {
    if (!startDate || !endDate) {
      toast.current?.show({
        severity: "warn",
        summary: "Cảnh báo",
        detail: "Vui lòng chọn ngày bắt đầu và ngày kết thúc",
        life: 3000,
      });
      return;
    }

    if (startDate > endDate) {
      toast.current?.show({
        severity: "warn",
        summary: "Cảnh báo",
        detail: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
        life: 3000,
      });
      return;
    }

    if (selectedTaskId) {
      dispatch(
        setDeadline({
          id: selectedTaskId,
          data: {
            startTime: formatDate(startDate),
            endTime: formatDate(endDate),
          },
        })
      );
    }
    onClose();
  };
  useEffect(() => {
    if (setDeadlineData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Cập nhật thời gian thành công",
        life: 3000,
      });
      setStartDate(null);
      setEndDate(null);
    }
    if (setDeadlineError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `Cập nhật thời gian thất bại`,
        life: 3000,
      });
    }
  }, [setDeadlineData, setDeadlineError]);

  const footerContent = (
    <div>
      {roleName && (
        <>
          <button
            className="custom-button"
            onClick={() => onClose()}
            style={{ backgroundColor: "#808080", color: "#ffffff" }}
          >
            Huỷ
          </button>
          <button className="custom-button" onClick={handleSave}>
            Lưu
          </button>
        </>
      )}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        header="Cập nhật thời gian"
        visible={open}
        // style={{ width: "50vw" }}
        onHide={() => {
          onClose();
        }}
        footer={footerContent}
        className="w-10 md:w-4"
      >
        <div className="card flex justify-content-between items-center">
          <div className="flex-auto mr-2">
            <label htmlFor="start-date" className="font-bold block mb-2">
              Ngày bắt đầu
            </label>
            <Calendar
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              dateFormat="dd/mm/yy"
              locale="vi"
              className="w-full"
            />
          </div>
          <div className="flex-auto ml-2">
            <label htmlFor="end-date" className="font-bold block mb-2">
              Ngày kết thúc
            </label>
            <Calendar
              value={endDate}
              onChange={(e) => setEndDate(e.value)}
              dateFormat="dd/mm/yy"
              locale="vi"
              className="w-full"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
