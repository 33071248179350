import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { site } from "../api/url_helper";
import { log } from "console";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Authmiddleware = (props: any) => {
    const navigate = useNavigate();

    let query = useQuery();
    const id = query.get("projectId");
    const guest_token = query.get("token");
    const path = props.path;

    useEffect(() => {
        const getAuth = async () => {
            try {
                const data = await axios.get(`${site}/auth/get-auth`, {
                    withCredentials: true,
                });
                if (data.status === 200) {
                    const auth = data.data.auth;
                    if (auth) {
                        if (path === "/list-project") {
                            navigate(path);
                        } else if (path === "/user") {
                            navigate(path);
                        } else {
                            navigate("/dashboard?projectId=" + id, {
                                replace: true,
                            });
                        }
                    } else {
                        navigate("/login");
                    }
                } else {
                    navigate("/login");
                }
            } catch (error) {
                navigate("/login");
            }
        };

        getAuth();
    }, [navigate]);

    return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
