import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  loginFail,
  loginSuccess,
  registerFail,
  registerSuccess,
} from "./slice";
import { login, register } from "../../api/backend_helper";

function* onLogin(action: PayloadAction<any>): Generator<any, void, any> {
  try {
    const response = yield call(login, action.payload);
    yield put(loginSuccess(response));
    const encodedRole = btoa(response.role);
    localStorage.setItem("role", encodedRole);
    localStorage.setItem("username", response.name);
    localStorage.setItem("accessToken", response.token);
  } catch (error) {
    yield put(loginFail("Login failed"));
  }
}

function* RegisterSaga(action: PayloadAction<any>): Generator<any, void, any> {
  try {
    const data = yield call(register, action.payload);
    yield put(registerSuccess(data));
  } catch (error) {
    yield put(registerFail(error));
  }
}

function* AuthSaga(): Generator<any, void, any> {
  yield takeLatest("auth/login", onLogin);
  yield takeLatest("auth/register", RegisterSaga);
}

export default AuthSaga;
