import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  createTitles,
  getTitlesData,
  updateDataTitles,
} from "../../api/backend_helper";
import {
  getTitleDataAllSuccess,
  getTitleDataAllFail,
  addTitlesSuccess,
  addTitlesFail,
  updateTitlesFail,
  updateTitlesSuccess,
} from "./slice";

function* fetchTitlesDataAll(
  action: PayloadAction<{ workTaskId: string; step_name: string }>
): Generator<any, void, any> {
  try {
    const { workTaskId, step_name } = action.payload;
    const response = yield call(getTitlesData, workTaskId, step_name);
    yield put(getTitleDataAllSuccess(response));
  } catch (error) {
    yield put(
      getTitleDataAllFail("Failed to fetch title data or handle error message")
    );
  }
}

function* createTitleSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(createTitles, action.payload);
    yield put(addTitlesSuccess(data));
  } catch (error) {
    yield put(addTitlesFail("Create Title Failed"));
  }
}

function* updateTitlesSaga(
  action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateDataTitles, id, data);
    yield put(updateTitlesSuccess(response));
  } catch (error) {
    yield put(updateTitlesFail(error));
  }
}

function* TitlesSaga(): Generator<any, void, any> {
  yield takeLatest("titles/getTitleDataAll", fetchTitlesDataAll);
  yield takeLatest("titles/addTitles", createTitleSaga);
  yield takeLatest("titles/updateTitles", updateTitlesSaga);
}

export default TitlesSaga;
