import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
    userData: any | null;
    error: string | null;
    loading: boolean;
}

const initialState: UserState = {
    userData: null,
    error: null,
    loading: false,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getAllUser: (
            state,
            action: PayloadAction<{ page: number; limit: number }>
        ) => {
            state.loading = true;
        },
        getAllUserSuccess: (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.userData = action.payload;
            state.error = null;
        },
        getAllUserFail: (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.userData = null;
            state.error = action.payload;
        },
    },
});

export const { getAllUser, getAllUserFail, getAllUserSuccess } =
    userSlice.actions;

export default userSlice.reducer;
