import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  addProjectSuccess,
  addProjectFail,
  getDataAllSuccess,
  getDataAllFail,
  deleteProjectFail,
  deleteProjectSuccess,
  updateProjectFail,
  updateProjectSuccess,
} from "./slice";
import {
  createProject,
  getProjectDataAll,
  deleteDataProject,
  updateDataProject,
} from "../../api/backend_helper";

function* createProjectSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(createProject, action.payload);
    yield put(addProjectSuccess(data));
  } catch (error) {
    yield put(addProjectFail("Create Project failed"));
  }
}

function* fetchProjectDataAll(
  action: PayloadAction<{ page: number; limit: number }>
): Generator<any, void, any> {
  try {
    const response = yield call(getProjectDataAll, action.payload);
    yield put(getDataAllSuccess(response));
  } catch (error) {
    yield put(getDataAllFail("List Project Data Error"));
  }
}

function* deleteProjectSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(deleteDataProject, action.payload);
    yield put(deleteProjectSuccess(data));
  } catch (error) {
    yield put(deleteProjectFail(error));
  }
}

function* updateProjectSaga(
  action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateDataProject, id, data);
    yield put(updateProjectSuccess(response));
  } catch (error) {
    yield put(updateProjectFail(error));
  }
}

function* ProjectSaga(): Generator<any, void, any> {
  yield takeLatest("project/getDataAll", fetchProjectDataAll);
  yield takeLatest("project/addProject", createProjectSaga);
  yield takeLatest("project/deleteProject", deleteProjectSaga);
  yield takeLatest("project/updateProject", updateProjectSaga);
}

export default ProjectSaga;
