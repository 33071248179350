import React, { useEffect, useRef, useState, useContext } from "react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SelectLanguage from "../../components/SelectLanguage";
import SidebarMenu from "./SidebarMenu";
import { Badge } from "primereact/badge";
import { logout } from "../../store/auth/slice";
import ModelProjectCreate from "../../pages/Dashboard/Project/model-project-create";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { getDataAll } from "../../store/project/slice";
import { ProjectContext } from "../../contexts/data/ProjectContext";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { site } from "../../api/url_helper";
import ActionMenu from "../../components/ActionMenu";
interface ProjectProps {
    id: number;
    name: string;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Header() {
    const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
    const [openProject, setOpenProject] = useState<boolean>(false);
    const [projectList, setProjectList] = useState<ProjectProps[]>([]);
    const accountMenu = useRef<any>(null);
    const notifyMenu = useRef<any>(null);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const navigate = useNavigate();
    const { roleName } = useContext(ProjectContext);
    const userName = localStorage.getItem("username");

    const location = useLocation();
    const { state } = location;
    const project = state?.project;

    let query = useQuery();
    const id = query.get("projectId");

    const {
        selectedProjectId,
        setSelectedProjectId,
        isLoadProject,
        selectNameProject,
        isLoad,
    } = useContext(ProjectContext);

    const { loading, projectData } = useSelector(
        (state: any) => ({
            loading: state.Auth.loading,
            projectData: state.Project.projectData,
        }),
        shallowEqual
    );

    // neu id co gia tri thi goi api lay du lieu
    useEffect(() => {
        const fetchData = async () => {
            if (id && id !== "null") {
                const project = await axios.get(
                    `${site}/projects/get-by-id?projectId=${id}`,
                    { withCredentials: true }
                );
                if (project.data.success) {
                    setSelectedProjectId(project.data.result[0]);
                }
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Cập nhật danh sách dự án khi có dữ liệu từ store
    useEffect(() => {
        if (projectData) {
            setProjectList(projectData.result.items);
        }
    }, [projectData]);

    // Lấy dữ liệu dự án ban đầu
    useEffect(() => {
        if (location.pathname !== "/list-project") {
            dispatch(
                getDataAll({ page: 1, limit: projectData?.result?.totalItems })
            );
        }
    }, [
        dispatch,
        isLoadProject,
        projectData?.result?.totalItems,
        location.pathname,
    ]);
    useEffect(() => {
        if (project) {
            setSelectedProjectId(project);
        }
    }, [project]);

    // Đặt giá trị ban đầu cho selectedProjectId nếu nó chưa được chọn
    useEffect(() => {
        if (!selectedProjectId && projectList.length > 0) {
            setSelectedProjectId(projectList[0]); // Đặt giá trị của phần tử đầu tiên
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProjectId, projectList]);

    useEffect(() => {
        if (selectNameProject) {
            const newProject = projectData?.result?.items.find(
                (e: any) => e.name === selectNameProject
            );
            if (newProject) {
                setSelectedProjectId(newProject);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectNameProject, projectData]);

    // Hàm để xử lý template cho dropdown
    const selectedProjectTemplate = (option: ProjectProps, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const projectOptionTemplate = (option: ProjectProps) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    // Các mục menu cho hồ sơ tài khoản
    const profileMenuItems = [
        {
            label: "Hồ sơ",
            items: [
                {
                    label: "Thông tin tài khoản",
                    icon: "pi pi-refresh",
                },
                {
                    label: "Đăng xuất",
                    icon: "pi pi-upload",
                    command: async () => {
                        dispatch(logout());
                        navigate("/login");
                    },
                },
            ],
        },
    ];

    // Các mục menu thông báo
    const notifyMenuItems = [
        {
            label: "Thông báo",
            items: [
                {
                    label: "Thông báo 1",
                    icon: "pi pi-refresh",
                },
                {
                    label: "Thông báo 2",
                    icon: "pi pi-upload",
                },
            ],
        },
    ];

    return isMobile ? (
        <>
            <div className="flex align-items-center justify-content-between bg-white h-4rem md:px-5">
                <div className="flex flex-row align-items-center">
                    {roleName === "pm" && (
                        <div>
                            <ActionMenu onViewDetail={() => {}} />
                        </div>
                    )}
                    <h3
                        style={{ fontSize: "16px" }}
                        className="cursor-pointer"
                        onClick={() => navigate("/")}
                    >
                        Tiến độ thực hiện
                    </h3>
                </div>
                <div className="flex flex-row align-items-center">
                    <div
                        className="p-3 cursor-pointer"
                        onClick={(event) => notifyMenu.current.toggle(event)}
                    >
                        <Menu
                            model={notifyMenuItems}
                            popup
                            ref={notifyMenu}
                            className="w-auto"
                            pt={{ menuitem: { className: "mx-2" } }}
                        />
                        <i
                            className="pi pi-bell p-overlay-badge"
                            style={{ fontSize: "1.5rem" }}
                        >
                            <Badge value="2"></Badge>
                        </i>
                    </div>
                    <SelectLanguage />
                    <div
                        className="flex align-items-center cursor-pointer p-3 gap-2 border-round text-700"
                        onClick={(event) => accountMenu.current.toggle(event)}
                    >
                        <Menu
                            model={profileMenuItems}
                            popup
                            ref={accountMenu}
                            className="w-auto"
                            pt={{ menuitem: { className: "mx-2" } }}
                        />
                        <Avatar
                            image="/images/avatas/avatar-null.png"
                            shape="circle"
                        />

                        <div className="flex flex-column">
                            <span className="font-bold uppercase">
                                {userName}
                            </span>
                            <span className="font-normal">
                                {roleName === "pm" ? "ADMIN" : "CUSTOMER"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* SidebarMenu Mobile */}
            <div className="card flex justify-content-center">
                <Sidebar
                    header={
                        <div className="pl-3">
                            <Image
                                src="/images/logo/logo.png"
                                alt="Image"
                                width="50"
                            />
                        </div>
                    }
                    visible={mobileMenuVisible}
                    onHide={() => setMobileMenuVisible(false)}
                >
                    <SidebarMenu />
                </Sidebar>
            </div>
            <ModelProjectCreate
                open={openProject}
                onClose={() => setOpenProject(false)}
            />
            {roleName === "pm" && (
                <div className="flex p-3 align-items-center">
                    <button
                        className="custom-button w-3 px-0 "
                        onClick={() => setOpenProject(true)}
                        style={{ fontSize: "14px" }}
                    >
                        Tạo mới
                    </button>
                    <Dropdown
                        value={selectedProjectId}
                        onChange={(e: DropdownChangeEvent) =>
                            setSelectedProjectId(e.value)
                        }
                        options={projectList}
                        optionLabel="name"
                        placeholder="Chọn dự án"
                        filter
                        valueTemplate={selectedProjectTemplate}
                        itemTemplate={projectOptionTemplate}
                        className="w-full h-2 md:w-14rem ml-3 align-items-center"
                    />
                </div>
            )}
        </>
    ) : (
        <>
            <div className="flex align-items-center justify-content-between bg-white h-4rem md:px-5">
                <div className="flex flex-row align-items-center">
                    {roleName === "pm" && (
                        <div>
                            <ActionMenu onViewDetail={() => {}} />
                        </div>
                    )}

                    <h3
                        className="cursor-pointer"
                        onClick={() => navigate("/")}
                    >
                        Tiến độ thực hiện
                    </h3>
                    {roleName === "pm" && (
                        <>
                            <button
                                className="custom-button ml-4"
                                onClick={() => setOpenProject(true)}
                            >
                                Tạo mới
                            </button>
                            <Dropdown
                                value={selectedProjectId}
                                onChange={(e: DropdownChangeEvent) =>
                                    setSelectedProjectId(e.value)
                                }
                                options={projectList}
                                optionLabel="name"
                                placeholder="Chọn dự án"
                                filter
                                valueTemplate={selectedProjectTemplate}
                                itemTemplate={projectOptionTemplate}
                                className="w-full md:w-14rem ml-3"
                            />
                        </>
                    )}
                </div>
                <div className="flex flex-row align-items-center">
                    <div
                        className="p-3 cursor-pointer"
                        onClick={(event) => notifyMenu.current.toggle(event)}
                    >
                        <Menu
                            model={notifyMenuItems}
                            popup
                            ref={notifyMenu}
                            className="w-auto"
                            pt={{ menuitem: { className: "mx-2" } }}
                        />
                        <i
                            className="pi pi-bell p-overlay-badge"
                            style={{ fontSize: "1.5rem" }}
                        >
                            <Badge value="2"></Badge>
                        </i>
                    </div>
                    <SelectLanguage />
                    <div
                        className="flex align-items-center cursor-pointer p-3 gap-2 border-round text-700"
                        onClick={(event) => accountMenu.current.toggle(event)}
                    >
                        <Menu
                            model={profileMenuItems}
                            popup
                            ref={accountMenu}
                            className="w-auto"
                            pt={{ menuitem: { className: "mx-2" } }}
                        />
                        <Avatar
                            image="/images/avatas/avatar-null.png"
                            shape="circle"
                        />

                        <div className="flex flex-column">
                            <span className="font-bold uppercase">
                                {userName}
                            </span>
                            <span className="font-normal">
                                {roleName === "pm" ? "Admin" : "Customer"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* SidebarMenu Mobile */}
            <div className="card flex justify-content-center">
                <Sidebar
                    header={
                        <div className="pl-3">
                            <Image
                                src="/images/logo/logo.png"
                                alt="Image"
                                width="50"
                            />
                        </div>
                    }
                    visible={mobileMenuVisible}
                    onHide={() => setMobileMenuVisible(false)}
                >
                    <SidebarMenu />
                </Sidebar>
            </div>
            <ModelProjectCreate
                open={openProject}
                onClose={() => setOpenProject(false)}
            />
        </>
    );
}

export default Header;
