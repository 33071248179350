import React, { useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../../store/auth/slice";

export default function Login() {
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checked, setChecked] = useState<boolean>(false);

    const { dataLogin, errorLogin } = useSelector(
        (state: any) => ({
            dataLogin: state.Auth.dataLogin,
            errorLogin: state.Auth.errorLogin,
        }),
        shallowEqual
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .required("Vui lòng nhập địa chỉ email")
                .email("Email không hợp lệ"),
            password: Yup.string().required("Mật khẩu không được để trống"),
        }),
        onSubmit: (values: any) => {
            dispatch(login(values));
        },
    });

    const handleButtonClick = () => {
        formik.handleSubmit();
    };

    useEffect(() => {
        if (dataLogin) {
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Đăng nhập thành công",
                life: 3000,
            });
            setTimeout(() => {
                navigate("/");
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLogin, navigate]);

    useEffect(() => {
        if (errorLogin) {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: `Đăng nhập thất bại. ${errorLogin}`,
                life: 3000,
            });
        }
    }, [errorLogin]);

    return (
        <div className="card p-5 w-12 md:w-6 lg:w-3" style={{ height: "65vh" }}>
            <form onSubmit={formik.handleSubmit}>
                <Toast ref={toast} />
                <div className="card bg-white p-3 border-round-xl">
                    <div className="text-center p-5">
                        <h4 className="text-3xl">Đăng nhập</h4>
                    </div>
                    <div className="flex flex-column mb-4">
                        <label htmlFor="email" className="mb-2">
                            Email
                        </label>
                        <InputText
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="email"
                            name="email"
                            autoComplete="on"
                            type="text"
                            placeholder="Email"
                            className="w-full p-3"
                            invalid={
                                formik.touched.email && formik.errors.email
                                    ? true
                                    : false
                            }
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <p
                                style={{
                                    color: "red",
                                    marginTop: "5px",
                                    fontSize: "0.9rem",
                                }}
                            >
                                {String(formik.errors.email)}
                            </p>
                        ) : null}
                    </div>
                    <div className="flex flex-column mb-4">
                        <label className="mb-2">Mật khẩu</label>
                        <Password
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Mật khẩu"
                            autoComplete="on"
                            inputClassName="w-full p-3"
                            pt={{
                                iconField: { root: { className: "w-full" } },
                            }}
                            toggleMask
                            feedback={false}
                            invalid={
                                formik.touched.password &&
                                formik.errors.password
                                    ? true
                                    : false
                            }
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <p
                                style={{
                                    color: "red",
                                    marginTop: "5px",
                                    fontSize: "0.9rem",
                                }}
                            >
                                {String(formik.errors.password)}
                            </p>
                        ) : null}
                    </div>
                    <div className="flex align-items-center justify-content-between mb-5 gap-5">
                        <div className="flex align-items-center">
                            {/* <Checkbox
                                inputId="rememberLogin"
                                checked={checked}
                                onChange={(e: any) => setChecked(e.checked)}
                                className="mr-2"
                            ></Checkbox>
                            <label htmlFor="rememberLogin" className="text-sm">
                                Lưu mật khẩu
                            </label> */}
                        </div>
                        <Link
                            to={`/verify-email?type=forget-password`}
                            className="font-medium text-sm no-underline text-right cursor-pointer"
                            style={{ color: "var(--primary-color)" }}
                        >
                            Quên mật khẩu?
                        </Link>
                    </div>
                    <Button
                        type="submit"
                        label="Đăng nhập"
                        className="w-full my-5"
                        onClick={handleButtonClick}
                    />
                    <div className="font-medium text-sm flex align-items-center justify-content-center pt-5 gap-2">
                        <Link
                            to={"/register"}
                            className="font-medium text-sm no-underline text-right cursor-pointer"
                            style={{ color: "var(--primary-color)" }}
                        >
                            Đăng ký tài khoản
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    );
}
