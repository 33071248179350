import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setDataNull } from "../../../store/titles/slice";
import { Toast } from "primereact/toast";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { updateTitles } from "../../../store/titles/slice";

export default function ModelEditTitleTask({
  visible,
  onHide,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
}) {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const { roleName } = useContext(ProjectContext);

  const { updateData, errorUpdate } = useSelector(
    (state: any) => ({
      updateData: state.Titles.updateData,
      errorUpdate: state.Titles.errorUpdate,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: rowData?.name || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Tên hạng mục không được để trống"),
    }),
    onSubmit: (values: any) => {
      const data: any = {
        name: values.name,
      };

      dispatch(updateTitles({ id: rowData?.id, data }));
      onHide();
    },
  });

  useEffect(() => {
    if (updateData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Sửa hạng mục thành công",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (errorUpdate) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Sửa hạng mục thất bại",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, errorUpdate]);

  const footerContent = (
    <div>
      {roleName === "pm" && (
        <>
          <button
            className="custom-button"
            onClick={() => onHide()}
            style={{ backgroundColor: "#808080", color: "#ffffff" }}
          >
            Huỷ
          </button>
          <button
            className="custom-button"
            onClick={() => formik.handleSubmit()}
          >
            Lưu
          </button>
        </>
      )}
    </div>
  );
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Sửa hạng mục</span>
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => onHide()}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div className="flex flex-column mb-4">
          <label htmlFor="name" className="mb-2 font-semibold">
            Tên hạng mục
          </label>
          <InputText
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="name"
            name="name"
            autoComplete="on"
            type="text"
            className="w-full p-3"
            invalid={formik.touched.name && formik.errors.name ? true : false}
          />
          {formik.touched.name && formik.errors.name ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
              }}
            >
              {String(formik.errors.name)}
            </p>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
}
