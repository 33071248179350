import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  addNotesSuccess,
  addNotesFail,
  getNotesDataAllSuccess,
  getNotesDataAllFail,
  updateNotesFail,
  updateNotesSuccess,
  deleteNotesSuccess,
  deleteNotesFail,
} from "./slice";
import { getNotesDataById, deleteDataNotes } from "../../api/backend_helper";
import axios from "axios";
import * as URL_API from "../../api/url_helper";

function* createNotesSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const { title, description, workTaskId, uploadedFiles } = action.payload;
    const formData = new FormData();
    if (uploadedFiles && uploadedFiles.length > 0) {
      uploadedFiles.forEach((file: any) => {
        if (file.file) {
          formData.append("files", file.file, file.fileName);
        }
      });
    }
    formData.append("description", description);
    formData.append("workTaskId", workTaskId);
    formData.append("title", title);
    const response = yield call(axios.post, URL_API.CREATE_NOTES, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };
    yield put(addNotesSuccess(dataUpdate));
  } catch (error) {
    yield put(addNotesFail("Create notes failed"));
  }
}

function* updateNoteSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const { files, description, id, title, files_remove } = action.payload;
    const formData = new FormData();
    if (description) {
      formData.append("description", description);
    }
    if (title) {
      formData.append("title", title);
    }

    if (files && files.length > 0) {
      files.forEach((file: any) => {
        if (file.file) {
          formData.append("files", file.file, file.fileName);
        }
      });
    }
    if (files_remove) {
      formData.append("files_remove", JSON.stringify(files_remove));
    }

    const response = yield call(
      axios.put,
      `${URL_API.UPDATE_NOTES}/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    const dataUpdate = response.data;
    yield put(updateNotesSuccess(dataUpdate));
  } catch (error) {
    yield put(updateNotesFail("Failed to update notes data"));
  }
}

function* fetchNotesDataAll(
  action: PayloadAction<String>
): Generator<any, void, any> {
  try {
    const response = yield call(getNotesDataById, action.payload);
    yield put(getNotesDataAllSuccess(response));
  } catch (error) {
    yield put(getNotesDataAllFail("Failed to fetch notes data"));
  }
}

function* deleteNotesSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(deleteDataNotes, action.payload);
    yield put(deleteNotesSuccess(data));
  } catch (error) {
    yield put(deleteNotesFail(error));
  }
}

function* NotesSaga(): Generator<any, void, any> {
  yield takeLatest("notes/getNotesDataAll", fetchNotesDataAll);
  yield takeLatest("notes/addNotes", createNotesSaga);
  yield takeLatest("notes/updateNotes", updateNoteSaga);
  yield takeLatest("notes/deleteNotes", deleteNotesSaga);
}

export default NotesSaga;
