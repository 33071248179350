import TimelineTask from "./Timeline/timeline";
import DetailTask from "./Task/detail-task";
import { useSelector, shallowEqual } from "react-redux";

function Dashboard() {
  const { stepsData } = useSelector(
    (state: any) => ({
      stepsData: state.Steps.stepsData,
    }),
    shallowEqual
  );

  return (
    <div className="dashboard-container" style={{ backgroundColor: "#F2F2F5" }}>
      <div
        className="flex flex-wrap card header-bar mb-3 pl-3"
        style={{
          backgroundColor: "#F0F0F0",
          borderBottom: "0.2px solid #BABABA",
        }}
      >
        <div className="flex align-items-start w-7 md:w-4 lg:w-3 xl:w-2 flex-column justify-content-center flex-auto h-5rem">
          <p className="font-light text-grey-300">Tên dự án</p>
          <h3 className="font-semibold text-grey-300">
            {stepsData?.result?.name}
          </h3>
        </div>
        <div className="flex align-items-start w-5 md:w-4 lg:w-3 xl:w-2 flex-column justify-content-center flex-auto h-5rem">
          <p className="font-light text-grey-300">Khách hàng</p>
          <h3 className="font-semibold text-grey-300">
            {stepsData?.result?.cusName}
          </h3>
        </div>
        <div className="flex align-items-start w-7 md:w-4 lg:w-3 xl:w-2 flex-column justify-content-center flex-auto h-5rem">
          <p className="font-light text-grey-300">Mã khách hàng</p>
          <h3 className="font-semibold text-grey-300">
            {stepsData?.result?.cusCode}
          </h3>
        </div>
        <div className="flex align-items-start w-5 md:w-4 lg:w-3 xl:w-2 flex-column justify-content-center flex-auto h-5rem">
          <p className="font-light text-grey-300">Số điện thoại</p>
          <h3 className="font-semibold text-grey-300">
            {stepsData?.result?.cusPhone}
          </h3>
        </div>
        <div
          className="flex align-items-start w-7 md:w-4 lg:w-3 xl:w-2 flex-column justify-content-center flex-auto h-5rem"
          style={{ wordBreak: "break-all", paddingRight: "5px" }}
        >
          <p className="font-light text-grey-300">Email</p>
          <h3 className="font-semibold text-grey-300">
            {stepsData?.result?.cusEmail}
          </h3>
        </div>
      </div>
      <div className="md:mx-3 md:p-4 md:mb-3" style={{ borderRadius: "15px" }}>
        <TimelineTask />
      </div>
      <DetailTask />
    </div>
  );
}

export default Dashboard;
