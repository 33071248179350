import React, { useRef, useContext, useState } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../contexts/data/ProjectContext";
import { site, site_frontend } from "../api/url_helper";
import axios from "axios";
import { Dialog } from "primereact/dialog";

const ActionMenu = ({ onViewDetail }: { onViewDetail: () => void }) => {
    const menuAction = useRef<Menu>(null);
    const navigate = useNavigate();
    const { selectedProjectId } = useContext(ProjectContext);
    const [link, setLink] = useState<string>("");
    const [visible, setVisible] = useState<boolean>(false);

    const sharedLink = async () => {
        let link: string = "";
        if (selectedProjectId) {
            const url = `${site_frontend}/verify-link?projectId=${selectedProjectId.id}`;
            const getLink = await axios.get(
                `${site}/projects/get-link?url=${url}`,
                {
                    withCredentials: true,
                }
            );
            link = getLink.data.url;
            setLink(link);
        }

        setVisible(true);
    };

    const actions = [
        {
            label: "Danh sách dự án",
            icon: "pi pi-list",
            command: () => navigate("/list-project"),
        },
        {
            label: "Danh sách người dùng",
            icon: "pi pi-user",
            command: () => navigate("/user"),
        },
        {
            label: "Chia sẻ link",
            icon: "pi pi-link",
            command: () => sharedLink(),
        },
    ];

    return (
        <div className="flex justify-content-end align-items-center">
            <div className="flex justify-content-end align-items-center">
                <Menu
                    model={actions}
                    popup
                    ref={menuAction}
                    className="action-menu"
                    style={{ width: "206px" }}
                />
                <Button
                    icon="pi pi-bars"
                    onClick={(e) => menuAction.current?.toggle(e)}
                    className="p-button-text"
                />
            </div>

            <Dialog
                header="Link chia sẻ"
                visible={visible}
                style={{ wordBreak: "break-all" }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
                className="w-10 md:w-7"
            >
                <p className="m-0">{link}</p>
            </Dialog>
        </div>
    );
};

export default ActionMenu;
