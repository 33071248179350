import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getWorkTaskDataAll,
  createWorkList,
  updateDataWorkTask,
} from "../../api/backend_helper";
import {
  getWorkTaskAllSuccess,
  getWorkTaskAllFail,
  addWorkTaskSuccess,
  addWorkTaskFail,
  updateWorkTaskSuccess,
  updateWorkTaskFail,
} from "./slice";

function* fetchWorkTaskDataAll(
  action: PayloadAction<{ projectId: string; stepsId: string; query?: any }>
): Generator<any, void, any> {
  try {
    const { projectId, stepsId, query } = action.payload;
    const response = yield call(getWorkTaskDataAll, projectId, stepsId, query);
    yield put(getWorkTaskAllSuccess(response));
  } catch (error) {
    yield put(getWorkTaskAllFail("Failed to fetch workTask data"));
  }
}

function* createWorkTask(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const response = yield call(createWorkList, action.payload);
    yield put(addWorkTaskSuccess(response));
  } catch (error) {
    yield put(addWorkTaskFail("Create workTask failed"));
  }
}

function* updateWorkTaskSaga(
  action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateDataWorkTask, id, data);
    yield put(updateWorkTaskSuccess(response));
  } catch (error) {
    yield put(updateWorkTaskFail(error));
  }
}

function* WorkTaskSaga(): Generator<any, void, any> {
  yield takeLatest("work-task/getWorkTaskAll", fetchWorkTaskDataAll);
  yield takeLatest("work-task/addWorkTask", createWorkTask);
  yield takeLatest("work-task/updateWorkTask", updateWorkTaskSaga);
}

export default WorkTaskSaga;
