import { all, fork } from "redux-saga/effects";
import AuthSaga from "./auth/saga";
import ProjectSaga from "./project/saga";
import StepsSaga from "./steps/saga";
import WorkTaskSaga from "./work-tasks/sage";
import TitlesSaga from "./titles/saga";
import TasksSaga from "./tasks/saga";
import NotesSaga from "./notes/saga";
import CommentSaga from "./comment/saga";
import UserSaga from "./user/saga";

export default function* rootSaga() {
    yield all([
        fork(AuthSaga),
        fork(ProjectSaga),
        fork(StepsSaga),
        fork(WorkTaskSaga),
        fork(TitlesSaga),
        fork(TasksSaga),
        fork(NotesSaga),
        fork(CommentSaga),
        fork(UserSaga),
    ]);
}
