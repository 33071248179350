/* eslint-disable jsx-a11y/img-redundant-alt */
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { InputTextarea } from "primereact/inputtextarea";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { addComment } from "../../../store/comment/slice";
import { getCommentDataAll, setDataNull } from "../../../store/comment/slice";
import moment from "moment";
import { Image } from "primereact/image";

function ModelSendMessage({ open, onClose }: { open: boolean; onClose: any }) {
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();
  const { selectedTitleTask, selectedEditTask, roleName } =
    useContext(ProjectContext);
  const [isLoadComment, setIsLoadComment] = useState<boolean>(false);
  const [uploadedImages, setUploadedImages] = useState<
    { imageUrl: string; file: File; fileName?: string; fileSize?: string }[]
  >([]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">{selectedTitleTask}</span>
    </div>
  );

  const { insertData, errorInsert, dataComment } = useSelector(
    (state: any) => ({
      insertData: state.Comments.insertData,
      errorInsert: state.Comments.errorInsert,
      dataComment: state.Comments.CommentData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selectedEditTask) {
      dispatch(getCommentDataAll(selectedEditTask?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEditTask, isLoadComment]);

  useEffect(() => {
    if (insertData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Tạo phản hồi thành công",
        life: 3000,
      });
      setIsLoadComment(!isLoadComment);
      formik.setFieldValue("content", "");
      setUploadedImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertData]);

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Tạo phản hồi thất bại`,
        life: 3000,
      });
    }
  }, [errorInsert]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: "",
      files: null,
    },
    onSubmit: (values: any) => {
      if (!values.content) {
        // Hiển thị thông báo lỗi nếu không có nội dung
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Vui lòng nhập nội dung",
          life: 3000,
        });
        return;
      }
      const dataToSend = {
        content: values.content,
        taskId: selectedEditTask?.id,
        files: uploadedImages || [],
      };
      dispatch(addComment(dataToSend));
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImages = Array.from(files).map((file) => {
        const imageUrl = URL.createObjectURL(file);
        return {
          imageUrl,
          file, // Lưu đối tượng File
          fileName: file.name,
          fileSize: `${(file.size / (1024 * 1024)).toFixed(2)}\u00A0MB`,
        };
      });
      setUploadedImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={open}
        modal
        header={headerElement}
        style={{ width: "50rem" }}
        onHide={() => {
          onClose();
          dispatch(setDataNull());
          setUploadedImages([]);
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div
          className="card flex flex-column align-items-start mt-2"
          style={{ width: "100%" }}
        >
          {dataComment &&
            dataComment.result.items.map((item: any) => {
              return (
                <div key={item.id} className="mb-4">
                  <div className="flex items-center w-full">
                    <img
                      src="/images/avatas/avatar-null.png"
                      alt="Profile Image"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                    <div className="flex items-center ml-3 mt-2">
                      <p className="font-semibold text-grey-300">
                        {item.username}
                      </p>
                      <p className="font-light text-grey-300 ml-3">
                        {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                      </p>
                    </div>
                  </div>
                  <p className="text-grey-300 mt-2">{item.content}</p>
                  <div className="flex flex-row overflow-x-auto mt-3">
                    {item.files && item.files.length > 0 ? (
                      item.files.map((file: any, index: number) => {
                        if (file.type === "image") {
                          return (
                            <div
                              key={index}
                              className="flex align-items-center justify-content-center mr-3"
                              style={{ flexShrink: 0 }}
                            >
                              {/* <Avatar
                                className="p-overlay-badge"
                                image={file.value}
                                style={{ width: "50px", height: "50px" }}
                              /> */}
                              <Image
                                className="p-overlay-badge"
                                src={file.value || ""} // Nếu không có imageUrl, sử dụng defaultImage
                                width="50"
                                height="50"
                                preview
                              />
                            </div>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <p className="text-grey-300">Không có tập tin nào</p>
                    )}
                  </div>
                </div>
              );
            })}
        </div>

        {roleName === "pm" && (
          <div
            className="card flex flex-column align-items-start mt-3"
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "1rem",
            }}
          >
            <InputTextarea
              autoResize
              placeholder="Viết comment"
              value={formik.values.content}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="content"
              rows={10}
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                boxShadow: "none",
              }}
            />
            <div className="flex flex-row overflow-x-auto surface-200 h-4rem align-items-center px-3 border-round-2xl mt-3 w-full">
              <div className="flex justify-content-start mr-2">
                <input
                  type="file"
                  name="files"
                  id="upload"
                  multiple
                  hidden
                  accept="image/jpeg, image/png, image/gif"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="upload"
                  style={{ padding: "10px 20px", borderRight: "10px" }}
                >
                  <i className="pi pi-paperclip"></i>
                </label>
              </div>
              {uploadedImages.map((item, index) => (
                <div
                  key={index}
                  className="flex align-items-center justify-content-center mr-3"
                  style={{ flexShrink: 0 }}
                >
                  <Image
                    className="p-overlay-badge"
                    src={item.imageUrl || ""} // Nếu không có imageUrl, sử dụng defaultImage
                    width="40"
                    height="40"
                    preview
                  />
                </div>
              ))}
              <div className="flex-grow-1"></div>

              <button
                className="custom-button align-items-center mr-3"
                onClick={() => formik.handleSubmit()}
                style={{ height: "35px" }}
              >
                Gửi
              </button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default ModelSendMessage;
