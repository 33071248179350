import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setDataNull } from "../../../store/steps/slice";
import { Toast } from "primereact/toast";
import { updateProject } from "../../../store/project/slice";
import { ProjectContext } from "../../../contexts/data/ProjectContext";

export default function ModalEditProject({
  visible,
  onHide,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
}) {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const { roleName } = useContext(ProjectContext);

  const { updateData, errorUpdate } = useSelector(
    (state: any) => ({
      updateData: state.Project.updateData,
      errorUpdate: state.Project.errorUpdate,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: rowData?.name || "",
      customer: rowData?.cusName || "",
      email: rowData?.cusEmail || "",
      phone: rowData?.cusPhone || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Tên dự án không được để trống"),
      customer: Yup.string().required("Tên khách hàng không được để trống"),
      email: Yup.string()
        .required("Email không được để trống")
        .email("Email không hợp lệ"),
      phone: Yup.string()
        .required("Số điện thoại không được để trống")
        .matches(/^\d+$/, "Số điện thoại phải là một số")
        .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
        .max(11, "Số điện thoại chỉ được phép có tối đa 11 chữ số"),
    }),
    onSubmit: (values: any) => {
      const data: any = {
        name: values.name,
        cusName: values.customer,
        cusEmail: values.email,
        cusPhone: values.phone,
      };

      dispatch(updateProject({ id: rowData?.id, data }));
      onHide();
    },
  });

  useEffect(() => {
    if (updateData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Sửa dự án thành công",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (errorUpdate) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Sửa dự án thất bại",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, errorUpdate]);

  const footerContent = (
    <div>
      {roleName === "pm" && (
        <>
          <button
            className="custom-button"
            onClick={() => onHide()}
            style={{ backgroundColor: "#808080", color: "#ffffff" }}
          >
            Huỷ
          </button>
          <button
            className="custom-button"
            onClick={() => formik.handleSubmit()}
          >
            Lưu
          </button>
        </>
      )}
    </div>
  );
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Sửa dự án</span>
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => onHide()}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div className="flex flex-column mb-4">
          <label htmlFor="name" className="mb-2 font-semibold">
            Tên dự án
          </label>
          <InputText
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="name"
            name="name"
            autoComplete="on"
            type="text"
            className="w-full p-3"
            invalid={formik.touched.name && formik.errors.name ? true : false}
          />
          {formik.touched.name && formik.errors.name ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
              }}
            >
              {String(formik.errors.name)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column mb-4">
          <label htmlFor="customer" className="mb-2 font-semibold">
            Khách hàng
          </label>
          <InputText
            value={formik.values.customer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="customer"
            name="customer"
            autoComplete="on"
            type="text"
            className="w-full p-3"
            invalid={
              formik.touched.customer && formik.errors.customer ? true : false
            }
          />
          {formik.touched.customer && formik.errors.customer ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
              }}
            >
              {String(formik.errors.customer)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column mb-4">
          <label htmlFor="email" className="mb-2 font-semibold">
            Email
          </label>
          <InputText
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="email"
            name="email"
            autoComplete="on"
            type="text"
            className="w-full p-3"
            invalid={formik.touched.email && formik.errors.email ? true : false}
          />
          {formik.touched.email && formik.errors.email ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
              }}
            >
              {String(formik.errors.email)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column mb-4">
          <label htmlFor="phone" className="mb-2 font-semibold">
            Số điện thoại
          </label>
          <InputText
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="phone"
            name="phone"
            autoComplete="on"
            type="text"
            className="w-full p-3"
            invalid={formik.touched.phone && formik.errors.phone ? true : false}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
              }}
            >
              {String(formik.errors.phone)}
            </p>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
}
