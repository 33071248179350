import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

import { useEffect, useRef } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation, Link } from "react-router-dom";
// import { changePassword, setDataNull } from '../../store/auth/slice';

function ResetPassword() {
  const toast = useRef(null);
  const dispatch = useDispatch();

  const location = useLocation();
  const email = location.state?.email;

  // const { dataChangePassword, errorChangePassword } = useSelector(
  //   (state) => ({
  //     dataChangePassword: state.Auth.dataChangePassword,
  //     errorChangePassword: state.Auth.errorChangePassword,
  //   }),
  //   shallowEqual
  // );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .min(6, "Mật khẩu phải chứa ít nhất 6 ký tự")
        .required("Mật khẩu mới là bắt buộc"),
      confirmPassword: Yup.string()
        // .oneOf([Yup.ref('newPassword'), null], 'Mật khẩu không khớp')
        .required("Mật khẩu không khớp"),
    }),
    onSubmit: async (values) => {
      // dispatch(changePassword({ email: email, password: values.newPassword }));
    },
  });

  // useEffect(() => {
  //   if (dataChangePassword) {
  //     toast.current?.show({
  //       severity: 'success',
  //       summary: 'Thành công',
  //       detail: 'Đổi mật khẩu thành công',
  //       life: 3000,
  //     });
  //     setTimeout(() => {
  //       window.location.href = '/login';
  //       dispatch(setDataNull());
  //     }, 1000);
  //   }
  // }, [dataChangePassword]);

  // useEffect(() => {
  //   if (errorChangePassword) {
  //     toast.current?.show({
  //       severity: 'error',
  //       summary: 'Thất bại',
  //       detail: 'Đổi mật khẩu thất bại',
  //     });
  //   }
  // }, [errorChangePassword]);

  return (
    <div className="card p-5" style={{ width: "450px", height: "65vh" }}>
      <form>
        <Toast ref={toast} />
        <div className="bg-white p-3 border-round-xl ">
          <div>
            <div className="text-center p-5 ">
              <h4 className="text-3xl">Đặt lại mật khẩu</h4>
            </div>
            <div className="flex flex-column mb-4">
              <label className="mb-2">Mật khẩu mới</label>
              <Password
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="newPassword"
                name="newPassword"
                type="password"
                placeholder="Mật khẩu"
                inputClassName="w-full p-3"
                pt={{ iconField: { root: { className: "w-full" } } }}
                toggleMask
                feedback={false}
                invalid={
                  formik.touched.newPassword && formik.errors.newPassword
                    ? true
                    : false
                }
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.newPassword)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column mb-4">
              <label className="mb-2">Nhập lại mật khẩu mới</label>
              <Password
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Mật khẩu"
                inputClassName="w-full p-3"
                pt={{ iconField: { root: { className: "w-full" } } }}
                toggleMask
                feedback={false}
                invalid={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? true
                    : false
                }
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.confirmPassword)}
                </p>
              ) : null}
            </div>
            <Button
              type="submit"
              label="Lưu"
              className="w-full my-5"
              // onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
