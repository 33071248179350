import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TitlesState {
  titlesData: any | null;
  error: string | null;
  loading: boolean;
  insertData: any | null;
  updateData: any | null;
  deleteData: any | null;
  errorInsert: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
}

const initialState: TitlesState = {
  titlesData: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const TitleSlice = createSlice({
  name: "titles",
  initialState,
  reducers: {
    getTitleDataAll: (
      state,
      action: PayloadAction<{ workTaskId: string; step_name: string }>
    ) => {
      state.loading = true;
    },
    getTitleDataAllSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.titlesData = action.payload;
      state.error = null;
    },
    getTitleDataAllFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.titlesData = null;
      state.error = action.payload;
    },
    addTitles: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addTitlesSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addTitlesFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateTitles: (state, action: PayloadAction<{ id: string; data: any }>) => {
      state.loading = true;
    },
    updateTitlesSuccess: (state, action) => {
      state.loading = false;
      state.updateData = action.payload;
      state.error = null;
    },
    updateTitlesFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.updateData = null;
    },
    deleteTitles: (state) => {
      state.loading = true;
    },
    deleteTitlesSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteTitlesFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDataNull: (state) => {
      state.titlesData = null;
      state.error = null;
      state.loading = true;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
    },
  },
});

export const {
  getTitleDataAll,
  getTitleDataAllSuccess,
  getTitleDataAllFail,
  addTitles,
  addTitlesSuccess,
  addTitlesFail,
  updateTitles,
  updateTitlesFail,
  updateTitlesSuccess,
  deleteTitles,
  deleteTitlesSuccess,
  deleteTitlesFail,
  setDataNull,
} = TitleSlice.actions;

export default TitleSlice.reducer;
