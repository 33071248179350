import React, { useRef, useState, useContext, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { deleteNotes, updateNotes } from "../../../store/notes/slice";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { Image } from "primereact/image";
import ModelDelete from "../../../components/ModalDelete";

export default function ModelEditNote({
  visible,
  onClose,
  rowData,
}: {
  visible: boolean;
  onClose: () => void;
  rowData: any;
}) {
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();
  const { selectedWorkTaskId, toggleIsLoad, roleName } =
    useContext(ProjectContext);
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      fileUrl: string;
      fileName: string;
      fileSize: string;
      iconUrl: string;
      file: File;
      isSelected: boolean;
    }[]
  >([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [modalDelete, setModalDelete] = useState(false);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Chỉnh sửa ghi chú</span>
    </div>
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => {
        const fileUrl = URL.createObjectURL(file);
        let iconUrl = "";

        // Determine icon based on file extension
        if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
          iconUrl = "/images/image/excel.png";
        } else if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
          iconUrl = "/images/image/word.png";
        } else if (
          file.name.endsWith(".jpg") ||
          file.name.endsWith(".jpeg") ||
          file.name.endsWith(".png") ||
          file.name.endsWith(".gif") ||
          file.name.endsWith(".bmp")
        ) {
          iconUrl = fileUrl; // Use the fileUrl itself as the icon for image files
        } else {
          iconUrl = "/images/image/default.png"; // Optional: Default icon for other file types
        }

        return {
          fileUrl,
          fileName: file.name,
          fileSize: `${(file.size / (1024 * 1024)).toFixed(2)}\u00A0MB`, // Use a non-breaking space
          iconUrl,
          file, // Save the file object itself
          isSelected: false,
        };
      });
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const fileOpject = selectedFiles?.map((fileName) => ({ key: fileName }));

  useEffect(() => {
    if (rowData) {
      const initialFiles = rowData?.files.map((file: any) => {
        let iconUrl;
        let fileType = file.type;

        // Determine the icon based on the file extension
        if (file.key.endsWith(".xlsx") || file.key.endsWith(".xls")) {
          iconUrl = "/images/image/excel.png";
          fileType = "excel";
        } else if (file.key.endsWith(".doc") || file.key.endsWith(".docx")) {
          iconUrl = "/images/image/word.png";
          fileType = "word";
        } else if (fileType === "image") {
          iconUrl = file.value; // Use the actual image URL if it's an image
        } else {
          iconUrl = "/images/image/default.png"; // Default icon for other file types
        }

        return {
          fileUrl: file.value,
          fileName: file.key,
          fileSize: "Unknown", // You can replace this with the actual size if available
          iconUrl,
          fileType,
          file: null, // No actual file information, just the URL
        };
      });

      setUploadedFiles((prevFiles) => [...initialFiles, ...prevFiles]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  //work-group
  const { updateNotesData, errorUpdate, deleteNotesData, deleteNotesError } =
    useSelector(
      (state: any) => ({
        updateNotesData: state.Notes.updateData,
        errorUpdate: state.Notes.errorUpdate,
        deleteNotesData: state.Notes.deleteData,
        deleteNotesError: state.Notes.errorDelete,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (updateNotesData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Chỉnh sửa ghi chú thành công",
        life: 3000,
      });
      onClose();
      toggleIsLoad();
      formik.resetForm();
      setUploadedFiles([]);
      setSelectedFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNotesData]);

  useEffect(() => {
    if (errorUpdate) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Chỉnh sửa ghi chú thất bại`,
        life: 3000,
      });
    }
  }, [errorUpdate]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "123",
      description: rowData ? rowData.description : "",
      workTaskId: selectedWorkTaskId,
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required("Ghi chú không được để trống"),
    }),
    onSubmit: (values: any) => {
      const dataToSend = {
        id: rowData?.id,
        description: values.description,
        title: values?.title,
        files: uploadedFiles || [],
        files_remove: fileOpject || [],
      };

      dispatch(updateNotes(dataToSend));
      setUploadedFiles([]);
    },
  });

  const handleDeleteToggle = (fileName: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.fileName === fileName
          ? { ...file, isSelected: !file.isSelected }
          : file
      )
    );

    setSelectedFiles((prevSelected) => {
      if (prevSelected.includes(fileName)) {
        return prevSelected.filter((name) => name !== fileName);
      } else {
        return [...prevSelected, fileName];
      }
    });
  };

  const acceptDelete = () => {
    if (rowData) {
      dispatch(deleteNotes(rowData?.id));
      setModalDelete(false);
    }
  };

  const footerContent = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {roleName && (
        <>
          <div className="button-container">
            <input
              type="file"
              name="files"
              id="upload"
              hidden
              accept=".xlsx,.xls,.doc,.docx,.jpg,.jpeg,.png,.gif"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="upload" className="upload-file">
              <i className="pi pi-paperclip"></i> Đính kèm
            </label>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="custom-button"
              onClick={() => setModalDelete(true)}
              style={{ backgroundColor: "red", color: "#ffffff" }}
            >
              Xóa
            </button>
            <button
              className="custom-button"
              onClick={() => formik.handleSubmit()}
            >
              Lưu
            </button>
          </div>
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (deleteNotesData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Xóa ghi chú thành công`,
        life: 3000,
      });
    }
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteNotesData]);

  useEffect(() => {
    if (deleteNotesError) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Xóa ghi chú thất bại",
        life: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteNotesError]);

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          onClose();
          setUploadedFiles([]);
          formik.resetForm();
          setSelectedFiles([]);
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div
          className="card flex flex-column align-items-start mt-3"
          style={{ width: "100%" }}
        >
          <label htmlFor="title" className="mb-2 font-semibold">
            Ghi chú
          </label>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="description"
            style={{ width: "100%" }}
            invalid={
              formik.touched.description && formik.errors.description
                ? true
                : false
            }
          />
          {formik.touched.description && formik.errors.description ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0", // Ensure it starts at the beginning of the line
                textAlign: "left", // Align text to the left
              }}
            >
              {String(formik.errors.description)}
            </p>
          ) : null}
        </div>
        {uploadedFiles.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between align-items-center h-5rem w-full"
          >
            <div className="flex items-center w-full">
              <Image
                className="p-overlay-badge"
                src={item.iconUrl} // Nếu không có imageUrl, sử dụng defaultImage
                width="40"
                height="40"
                preview
              />
              <p className="font-semibold text-grey-300 mt-3 ml-3">
                {item.fileName}
              </p>
            </div>
            <p className="text-grey-300">{item.fileSize}</p>
            <span
              className={`delete-icon ${item.isSelected ? "selected" : ""}`}
              onClick={() => handleDeleteToggle(item.fileName)}
              // style={{}}
            >
              &times;
            </span>
          </div>
        ))}
      </Dialog>

      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa ghi chú này không?
          </div>
        }
      />
    </div>
  );
}
