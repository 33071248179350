import React, { createContext, useState, ReactNode } from 'react';

interface DataContextType {
  socket?: any; 
  setSocket: React.Dispatch<React.SetStateAction<any>>; 
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  skTicket: string;
  setSkTicket: React.Dispatch<React.SetStateAction<string>>;
  updateUserCount?: number; 
  setUpdateUserCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  notification?: any; 
  setNotification: React.Dispatch<React.SetStateAction<any>>;
}

const defaultContextValue: DataContextType = {
  socket: undefined,
  setSocket: () => {},
  message: '',
  setMessage: () => {},
  skTicket: '',
  setSkTicket: () => {},
  updateUserCount: undefined,
  setUpdateUserCount: () => {},
  notification: undefined,
  setNotification: () => {},
};

export const DataContext = createContext<DataContextType>(defaultContextValue);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<string>('');
  const [socket, setSocket] = useState<any>(); 
  const [skTicket, setSkTicket] = useState<string>('');
  const [updateUserCount, setUpdateUserCount] = useState<number | undefined>(undefined);
  const [notification, setNotification] = useState<any>(); 

  return (
    <DataContext.Provider
      value={{
        socket,
        setSocket,
        message,
        setMessage,
        skTicket,
        setSkTicket,
        updateUserCount,
        setUpdateUserCount,
        notification,
        setNotification,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
