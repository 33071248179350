import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
    getTasksDataById,
    deleteDataTasks,
    setDeadline,
} from "../../api/backend_helper";

import {
    getTaskDataAllSuccess,
    getTaskDataAllFail,
    addTasksSuccess,
    addTasksFail,
    updateTasksFail,
    updateTasksSuccess,
    deleteTasksSuccess,
    deleteTasksFail,
    setDeadlineFail,
    setDeadlineSuccess,
} from "./slice";
import axios from "axios";
import * as URL_API from "../../api/url_helper";

function* fetchTasksDataAll(
    action: PayloadAction<number>
): Generator<any, void, any> {
    try {
        const response = yield call(getTasksDataById, action.payload);
        yield put(getTaskDataAllSuccess(response));
    } catch (error) {
        yield put(getTaskDataAllFail("Failed to fetch task data"));
    }
}

function* createTasksSaga(
    action: PayloadAction<any>
): Generator<any, void, any> {
    try {
        const formData = new FormData();
        formData.append("name", action.payload.name);
        formData.append("description", action.payload.description);
        formData.append("titleName", action.payload.titleName);
        formData.append("workTaskId", action.payload.workTaskId);
        if (action.payload.files && action.payload.files.length > 0) {
            action.payload.files.forEach((file: any) => {
                if (file.file) {
                    formData.append("files", file.file, file.fileName);
                }
            });
        }
        const response = yield call(
            axios.post,
            URL_API.CREATE_TASKS,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            }
        );
        const headers = { ...response.headers };
        const dataUpdate = { ...response.data, headers };
        yield put(addTasksSuccess(dataUpdate));
    } catch (error) {
        yield put(addTasksFail("Create task failed"));
    }
}

function* updateTasksSaga(
    action: PayloadAction<any>
): Generator<any, void, any> {
    try {
        const {
            id,
            type,
            files,
            isDone,
            files_remove,
            name,
            titleId,
            description,
        } = action.payload;

        const formData = new FormData();
        if (isDone !== undefined) {
            formData.append("isDone", isDone.toString());
        }
        if (type) {
            formData.append("type", type);
        }

        if (files && files.length > 0) {
            files.forEach((file: any) => {
                if (file.file) {
                    formData.append("files", file.file, file.fileName);
                }
            });
        }
        if (name) {
            formData.append("name", name.toString());
        }
        if (files_remove) {
            formData.append("files_remove", JSON.stringify(files_remove));
        }

        if (titleId) {
            formData.append("titleId", titleId);
        }

        if (description) {
            formData.append("description", description);
        }

        const response = yield call(
            axios.put,
            `${URL_API.UPDATE_TASKS}/${id}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            }
        );
        // const dataUpdate = { ...response.data, skipUpdate };
        const dataUpdate = response.data;
        yield put(updateTasksSuccess(dataUpdate));
    } catch (error) {
        yield put(updateTasksFail("Update task failed"));
    }
}

function* deleteTasksSaga(
    action: PayloadAction<any>
): Generator<any, void, any> {
    try {
        const data = yield call(deleteDataTasks, action.payload);
        yield put(deleteTasksSuccess(data));
    } catch (error) {
        yield put(deleteTasksFail(error));
    }
}

function* SetDeadlineSaga(
    action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
    try {
        const { id, data } = action.payload;
        const response = yield call(setDeadline, id, data);
        yield put(setDeadlineSuccess(response));
    } catch (error) {
        yield put(setDeadlineFail(error));
    }
}

function* TasksSaga(): Generator<any, void, any> {
    yield takeLatest("tasks/getTaskDataAll", fetchTasksDataAll);
    yield takeLatest("tasks/addTasks", createTasksSaga);
    yield takeLatest("tasks/updateTasks", updateTasksSaga);
    yield takeLatest("tasks/deleteTasks", deleteTasksSaga);
    yield takeLatest("tasks/setDeadline", SetDeadlineSaga);
}

export default TasksSaga;
