import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TasksState {
  tasksData: any | null;
  error: string | null;
  loading: boolean;
  insertData: any | null;
  updateData: any | null;
  deleteData: any | null;
  errorInsert: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
  setDeadlineData: any | null;
  setDeadlineError: any | null;
}

const initialState: TasksState = {
  tasksData: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
  setDeadlineData: null,
  setDeadlineError: null,
};

const TaskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    getTaskDataAll: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getTaskDataAllSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.tasksData = action.payload;
      state.error = null;
    },
    getTaskDataAllFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.tasksData = null;
      state.error = action.payload;
    },
    addTasks: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addTasksSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addTasksFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateTasks: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.updateData = null;
      state.errorUpdate = null;
    },
    updateTasksSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateData = action.payload;
      state.errorUpdate = null;
    },
    updateTasksFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateData = action.payload;
      state.updateData = null;
    },
    deleteTasks: (state) => {
      state.loading = true;
    },
    deleteTasksSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteTasksFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDeadline: (state, action: PayloadAction<{ id: string; data: any }>) => {
      state.loading = true;
    },
    setDeadlineSuccess: (state, action) => {
      state.loading = false;
      state.setDeadlineData = action.payload;
      state.setDeadlineError = null;
    },
    setDeadlineFail: (state, action) => {
      state.loading = false;
      state.setDeadlineData = null;
      state.setDeadlineError = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.tasksData = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
      state.setDeadlineError = null;
      state.setDeadlineData = null;
    },
  },
});

export const {
  getTaskDataAll,
  getTaskDataAllSuccess,
  getTaskDataAllFail,
  addTasks,
  addTasksSuccess,
  addTasksFail,
  updateTasks,
  updateTasksFail,
  updateTasksSuccess,
  deleteTasks,
  deleteTasksSuccess,
  deleteTasksFail,
  setDataNull,
  setDeadline,
  setDeadlineFail,
  setDeadlineSuccess,
} = TaskSlice.actions;

export default TaskSlice.reducer;
