import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { ProjectContext } from "../../../contexts/data/ProjectContext";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getTaskDataAll, setDataNull } from "../../../store/tasks/slice";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { updateTasks } from "../../../store/tasks/slice";
import { updateNotes } from "../../../store/notes/slice";
import { Image } from "primereact/image";

export default function ModelListImageTask({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) {
  const {
    selectedTitleTask,
    selectedEditTask,
    checkNoteEdit,
    setCheckNoteEdit,
    roleName,
  } = useContext(ProjectContext);
  const [uploadedImages, setUploadedImages] = useState<
    {
      imageUrl: string;
      file: File;
      fileName?: string;
      fileSize?: string;
      isSelected: boolean;
    }[]
  >([]);
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const {
    listTaskData,
    updateData,
    errorUpdate,
    listNotesData,
    updateNotesData,
    updateErrorNotes,
  } = useSelector(
    (state: any) => ({
      listTaskData: state.Tasks.tasksData,
      updateData: state.Tasks.updateData,
      errorUpdate: state.Tasks.errorUpdate,
      listNotesData: state.Notes.notesData,
      updateNotesData: state.Notes.updateData,
      updateErrorNotes: state.Notes.errorUpdate,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (checkNoteEdit) {
      if (listNotesData && listNotesData.result && listNotesData.result.items) {
        const filesArray = listNotesData.result.items.flatMap((item: any) =>
          item.files.map((file: any) => ({
            imageUrl: file.value,
            file: file.file, // Đảm bảo file được lưu trữ ở đây
            fileName: file.key,
            fileSize: "Unknown",
          }))
        );
        setUploadedImages(filesArray);
      }
    } else {
      if (listTaskData && listTaskData.result && listTaskData.result.files) {
        const filesArray = listTaskData.result.files.map((file: any) => ({
          imageUrl: file.value,
          file: file.file, // Đảm bảo file được lưu trữ ở đây
          fileName: file.key,
          fileSize: "Unknown",
        }));
        setUploadedImages(filesArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTaskData, listNotesData]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">{selectedTitleTask}</span>
    </div>
  );
  const fileOpject = selectedFiles?.map((fileName) => ({ key: fileName }));

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImages = Array.from(files).map((file) => {
        const imageUrl = URL.createObjectURL(file);
        return {
          imageUrl,
          file, // Lưu đối tượng File
          fileName: file.name,
          fileSize: (file.size / (1024 * 1024)).toFixed(2) + "MB",
          isSelected: false,
        };
      });
      setUploadedImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "upload",
    },

    onSubmit: (values) => {
      if (checkNoteEdit) {
        const dataToSend = {
          id: selectedEditTask?.id,
          description: selectedEditTask.description,
          title: selectedEditTask.title,
          files: uploadedImages || [],
          files_remove: fileOpject || [],
        };
        dispatch(updateNotes(dataToSend));
        // setUploadedImages([]);
        // setCheckNoteEdit(false);
      } else {
        const dataToSend = {
          id: selectedEditTask.id,
          type: values.type,
          files: uploadedImages || [],
          files_remove: fileOpject || [],
        };
        dispatch(updateTasks(dataToSend));
        // setUploadedImages([]);
        setSelectedFiles([]);
      }
    },
  });

  useEffect(() => {
    if (updateData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Thành công",
        life: 3000,
      });
      dispatch(setDataNull());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (errorUpdate) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Thất bại`,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdate]);

  useEffect(() => {
    if (updateNotesData) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Cập nhật thành công",
        life: 3000,
      });
      dispatch(setDataNull());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNotesData]);

  useEffect(() => {
    if (updateErrorNotes) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Cập nhật thất bại`,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateErrorNotes]);

  const footerContent = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {roleName === "pm" && (
        <div className="button-container">
          <input
            type="file"
            name="files"
            id="upload"
            multiple
            hidden
            accept="image/jpeg, image/png, image/gif"
            onChange={handleFileChange}
          />
          <label htmlFor="upload" className="upload-file">
            <i className="pi pi-paperclip"></i> Đính kèm
          </label>
        </div>
      )}

      {roleName && (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className="custom-button"
            onClick={() => {
              onClose();
              setCheckNoteEdit(false);
              setUploadedImages([]);
              dispatch(setDataNull());
            }}
            style={{ backgroundColor: "#808080", color: "#ffffff" }}
          >
            Huỷ
          </button>
          <button
            className="custom-button"
            onClick={() => formik.handleSubmit()}
          >
            Lưu
          </button>
        </div>
      )}
    </div>
  );

  const isImageFile = (fileName: any) => {
    // Danh sách các đuôi tệp ảnh hỗ trợ
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
    // Kiểm tra nếu tên tệp có đuôi nằm trong danh sách đuôi ảnh
    return imageExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
  };

  const imageFiles = uploadedImages.filter((item) =>
    isImageFile(item.fileName)
  );

  const handleDeleteToggle = (fileName: string) => {
    setUploadedImages((prevFiles) =>
      prevFiles.map((file) =>
        file.fileName === fileName
          ? { ...file, isSelected: !file.isSelected }
          : file
      )
    );

    setSelectedFiles((prevSelected) => {
      if (prevSelected.includes(fileName)) {
        return prevSelected.filter((name) => name !== fileName);
      } else {
        return [...prevSelected, fileName];
      }
    });
  };

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          onClose();
          setUploadedImages([]);
          dispatch(setDataNull());
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-6"
      >
        <div
          className="flex flex-wrap"
          style={{ gap: "1rem", justifyContent: "flex-start" }}
        >
          {imageFiles.length > 0 ? (
            imageFiles.map((item, index) => (
              <div
                key={index}
                className="flex align-items-center relative"
                style={{
                  flexBasis: "calc(20% - 1rem)",
                  marginBottom: "1rem",
                }}
              >
                <Image
                  className="p-overlay-badge"
                  src={item.imageUrl || ""} // Nếu không có imageUrl, sử dụng defaultImage
                  width="140"
                  height="140"
                  preview
                />
                <span
                  id="delete-image"
                  className={`delete-icon ${item.isSelected ? "selected" : ""}`}
                  onClick={() => handleDeleteToggle(item.fileName || "")}
                  style={{}}
                >
                  &times;
                </span>
              </div>
            ))
          ) : (
            <div
              className="flex align-items-start "
              style={{
                flexBasis: "calc(20% - 1rem)",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ddd",
                  backgroundColor: "#f0f0f0",
                  fontSize: "16px",
                  color: "#999",
                }}
              >
                No Image
              </div>
            </div>
          )}
        </div>
        <div className="button-container flex justify-content-start mt-3"></div>
      </Dialog>
    </div>
  );
}
