import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProjectState {
  projectData: any | null;
  error: string | null;
  loading: boolean;
  insertData: any | null;
  updateData: any | null;
  deleteData: any | null;
  errorInsert: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
}

interface Project {
  name: string;
  cusName: string;
  cusEmail: string;
  cusPhone: string;
}

const initialState: ProjectState = {
  projectData: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    getDataAll(state, action: PayloadAction<{ page: number; limit: number }>) {
      state.loading = true;
    },
    getDataAllSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.projectData = action.payload;
      state.error = null;
    },
    getDataAllFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.projectData = null;
      state.error = action.payload;
    },
    addProject: (state, action: PayloadAction<Project>) => {
      state.loading = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addProjectSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addProjectFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateProject: (
      state,
      action: PayloadAction<{ id: string; data: any }>
    ) => {
      state.loading = true;
    },
    updateProjectSuccess: (state, action) => {
      state.loading = false;
      // const index = state.projectData.findIndex(
      //   (item: any) => item.id === action.payload.id
      // );
      // if (index !== -1) {
      //   state.projectData[index] = action.payload;
      // }
      state.updateData = action.payload;
      state.error = null;
    },
    updateProjectFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteProject: (state) => {
      state.loading = true;
    },
    deleteProjectSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteProjectFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
    },
  },
});

export const {
  getDataAll,
  getDataAllSuccess,
  getDataAllFail,
  addProject,
  addProjectSuccess,
  addProjectFail,
  setDataNull,
  updateProject,
  updateProjectFail,
  updateProjectSuccess,
  deleteProject,
  deleteProjectFail,
  deleteProjectSuccess,
} = projectSlice.actions;

export default projectSlice.reducer;
