import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { getAllUser } from "../../store/user/slice";
import { Paginator } from "primereact/paginator";
import avatarDefault from "../../assets/images/avatas/avatar-null.png";
import ModalAddUser from "./ModalAddUser";

export default function User() {
  const dispatch = useDispatch();

  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [visible, setVisible] = useState(false);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const { userData, dataRegister } = useSelector(
    (state: any) => ({
      userData: state.User.userData,
      dataRegister: state.Auth.dataRegister,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAllUser({ page: (first + rows) / rows, limit: rows }));
  }, [dispatch, first, rows]);

  useEffect(() => {
    if (dataRegister) {
      dispatch(getAllUser({ page: (first + rows) / rows, limit: rows }));
    }
  }, [dispatch, first, rows, dataRegister]);

  useEffect(() => {
    setDataTable(userData?.result);
  }, [userData]);

  // Hàm để hiển thị giá trị role
  const roleBodyTemplate = (rowData: any) => {
    switch (rowData.role) {
      case "cus":
        return "Khách hàng";
      case "pm":
        return "Quản lý";
      case "dev":
        return "Nhân viên";
      default:
        return rowData.role; // Trả về giá trị gốc nếu không khớp với các giá trị trên
    }
  };

  const avatarBodyTemplate = (rowData: any) => {
    const avatarSrc = rowData.avatar || avatarDefault;
    return (
      <Avatar
        image={avatarSrc}
        shape="circle"
        className="my-2 justify-content-center"
      />
    );
  };

  return (
    <div className="dashboard-container bg-white border-round-md shadow-1 p-3 m-3">
      <button
        className="custom-button w-5 md:w-2 px-0 mb-3"
        onClick={() => setVisible(true)}
        style={{ fontSize: "14px" }}
      >
        Thêm mới nhân viên
      </button>
      <DataTable
        value={dataTable}
        emptyMessage="Không có dữ liệu"
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        size="small"
      >
        <Column
          header="STT"
          body={(rowData, options) => (
            <div className="text-center">{first + options.rowIndex + 1}</div>
          )}
          style={{ width: "3%", minWidth: "60px" }}
          alignHeader={"center"}
          align={"center"}
          frozen
        ></Column>
        <Column style={{ minWidth: "180px" }} header="Họ & tên" field="name" />
        <Column
          style={{ minWidth: "50px" }}
          align={"center"}
          alignHeader={"center"}
          header="Avatar"
          field="avatar"
          body={avatarBodyTemplate}
        />
        <Column style={{ minWidth: "250px" }} header="Email" field="email" />
        <Column
          style={{ minWidth: "100px" }}
          header="Quyền"
          field="role"
          body={roleBodyTemplate}
        />
      </DataTable>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={userData?.total}
        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
        onPageChange={onPageChange}
      />
      <ModalAddUser visible={visible} onHide={() => setVisible(false)} />
    </div>
  );
}
