import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";

import { useTranslation } from "react-i18next";

export default function SidebarMenu() {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("menu") || "null");
    setSelectedItem(item);
  }, []);

  const itemRenderer = (item: any) => (
    <Link
      to={item.url}
      className={`flex align-items-center p-menuitem-content p-menuitem-link border-round-md ml-5 mr-3 p-3 ${
        selectedItem === item.label ? "font-bold text-primary bg-green-50" : ""
      }`}
      onClick={() => {
        setSelectedItem(item.label);
        localStorage.setItem("menu", JSON.stringify(item.label));
      }}
    >
      <span className={item.icon} />
      <span className="mx-3 white-space-nowrap">{item.label}</span>
    </Link>
  );
  let items = [
    {
      template: () => {
        return <></>;
      },
    },
    {
      label: t("Home"),
      items: [
        {
          label: t("Dashboard"),
          icon: "pi pi-chart-bar",
          url: "/dashboard",
          template: itemRenderer,
        },
      ],
    },
    {
      label: t("Intern"),
      items: [
        {
          label: t("Intern list"),
          icon: "pi pi-users",
          url: "/intern",
          template: itemRenderer,
        },
        {
          label: t("Violate List"),
          icon: "pi pi-exclamation-triangle",
          url: "/violate",
          template: itemRenderer,
        },
      ],
    },
    {
      label: t("Partner"),
      items: [
        {
          label: t("Syndication"),
          icon: "pi pi-briefcase",
          url: "/syndication",
          template: itemRenderer,
        },
        {
          label: t("Receiving Factory"),
          icon: "pi pi-building",
          url: "/receiving-factory",
          template: itemRenderer,
        },
        {
          label: t("Dispatching Company"),
          icon: "pi pi-globe",
          url: "/dispatching-company",
          template: itemRenderer,
        },
      ],
    },
    {
      label: t("Support"),
      items: [
        {
          label: t("Notification"),
          icon: "pi pi-bell",
          url: "/notification",
          template: itemRenderer,
        },
        {
          label: t("Support"),
          icon: "pi pi-headphones",
          url: "/ticket",
          template: itemRenderer,
        },
      ],
    },
    {
      label: t("Categories"),
      items: [
        {
          label: t("Career category"),
          icon: "pi pi-list",
          url: "/career",
          template: itemRenderer,
        },
        {
          label: t("Status category"),
          icon: "pi pi-list",
          url: "/status",
          template: itemRenderer,
        },
        {
          label: t("Violate type"),
          icon: "pi pi-list",
          url: "/violate-type",
          template: itemRenderer,
        },
      ],
    },
  ];

  return (
    <div className="flex flex-column h-screen md:border-right-1 border-gray-200">
      <div className="hidden md:flex justify-content-center p-3">
        <Image src="images/logo/logo.png" alt="Image" width="80" />
      </div>
      <Menu
        model={items}
        className="w-full md:w-18rem border-noround overflow-auto border-none"
      />
    </div>
  );
}
