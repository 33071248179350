import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  dataLogin: any | null;
  errorLogin: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  dataByUserId: any | null;
  dataRegister: any | null;
  errorRegister: any | null;
}

const initialState: AuthState = {
  dataLogin: null,
  errorLogin: null,
  isAuthenticated: false,
  loading: false,
  dataByUserId: null,
  dataRegister: null,
  errorRegister: null,
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataLogin = action.payload;
      state.isAuthenticated = true;
      state.errorLogin = null;
    },
    loginFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.dataLogin = null;
      state.errorLogin = action.payload;
    },
    logout: (state) => {
      state.loading = true;
      state.isAuthenticated = false;
      state.dataLogin = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("role");
    },
    register: (state) => {
      state.loading = true;
    },
    registerSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataRegister = action.payload;
      state.errorRegister = null;
    },
    registerFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataRegister = null;
      state.errorRegister = action.payload;
    },
    setDataAuthNull: (state) => {
      state.dataLogin = null;
      state.dataRegister = null;
      state.errorLogin = null;
      state.errorRegister = null;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFail,
  logout,
  register,
  registerFail,
  registerSuccess,
  setDataAuthNull,
} = userSlice.actions;

export default userSlice.reducer;
