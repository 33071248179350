import React, { useState, useEffect, useRef, useMemo } from "react";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

interface Language {
  label: string;
  lang: string;
  flag: string;
}

const SelectLanguage: React.FC = () => {
  const menuLanguage = useRef<Menu>(null);
  const { t } = useTranslation();

  const languages = useMemo<Language[]>(
    () => [
      {
        label: "Tiếng Việt",
        lang: "vi",
        flag: "/images/flag/vn.png",
      },
      {
        label: "日本語",
        lang: "ja",
        flag: "/images/flag/jp.png",
      },
      {
        label: "English",
        lang: "en",
        flag: "/images/flag/uk.png",
      },
      // Thêm ngôn ngữ khác ở đây
    ],
    []
  );

  const flagItems = useMemo<any[]>(
    () =>
      languages.map((lang) => ({
        label: lang.label,
        icon: "pi pi-image",
        command: () => {
          changeLanguageAction(lang.lang);
        },
        template: (item: any) => (
          <div
            className="flex align-items-center gap-2 cursor-pointer hover:surface-100 py-2 px-4"
            onClick={(event) => {
              event.stopPropagation(); // Ngăn menu không chuyển đổi khi click
              changeLanguageAction(lang.lang);
              menuLanguage.current?.toggle(event);
            }}
          >
            <img src={lang.flag} alt={lang.label} width="22px" />
            <span>{lang.label}</span>
          </div>
        ),
      })),
    [languages]
  );

  const [selectedLang, setSelectedLang] = useState<string>("");
  const [flagSrc, setFlagSrc] = useState<string>("");
  const [selectedLable, seselectedLable] = useState<string>("");

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    if (currentLanguage) {
      setSelectedLang(currentLanguage);
      i18n.changeLanguage(currentLanguage);
      const selectedLanguage = languages.find(
        (lang) => lang.lang === currentLanguage
      );
      if (selectedLanguage) {
        setFlagSrc(selectedLanguage.flag);
      }
    }
  }, [languages]);

  useEffect(() => {
    const selectedLanguage = languages.find(
      (lang) => lang.lang === selectedLang
    );
    if (selectedLanguage) {
      setFlagSrc(selectedLanguage.flag);
      seselectedLable(selectedLanguage.label);
    }
  }, [selectedLang, languages]);

  const changeLanguageAction = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
  };

  return (
    <div
      className="flex align-items-center cursor-pointer p-3 gap-2"
      onClick={(event) => menuLanguage.current?.toggle(event)}
    >
      <Menu
        model={flagItems}
        popup
        ref={menuLanguage}
        className="w-auto"
        pt={{ menuitem: { className: "mx-2" } }}
      />
      <Avatar
        image={flagSrc}
        aria-controls="popup_menu_right"
        aria-haspopup
        style={{ height: "2rem", width: "2rem", borderRadius: "5px" }}
      />
      <span className="ml-2">{selectedLable}</span>
    </div>
  );
};

export default SelectLanguage;
