import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getStepsDataById,
  updateDataConfirm,
  updateDataStart,
} from "../../api/backend_helper";

import {
  getDataAllSuccess,
  getDataAllFail,
  updateConfirmFail,
  updateConfirmSuccess,
  updateStartFail,
  updateStartSuccess,
} from "./slice";

interface UpdateConfirmPayload {
  projectId: string;
  stepId: string;
}

function* fetchStepsDataAll(
  action: PayloadAction<number>
): Generator<any, void, any> {
  try {
    const response = yield call(getStepsDataById, action.payload);
    yield put(getDataAllSuccess(response));
  } catch (error) {
    yield put(getDataAllFail("Failed to fetch Steps data"));
  }
}

function* updateStepConfirmSaga(
  action: PayloadAction<UpdateConfirmPayload>
): Generator<any, void, any> {
  try {
    const data = yield call(updateDataConfirm, action.payload);
    yield put(updateConfirmSuccess(data));
  } catch (error) {
    yield put(updateConfirmFail("Create Steps failed"));
  }
}

function* updateStepStartSaga(
  action: PayloadAction<UpdateConfirmPayload>
): Generator<any, void, any> {
  try {
    const data = yield call(updateDataStart, action.payload);
    yield put(updateStartSuccess(data));
  } catch (error) {
    yield put(updateStartFail("Create Steps failed"));
  }
}

function* StepsSaga(): Generator<any, void, any> {
  yield takeLatest("steps/getDataAll", fetchStepsDataAll);
  yield takeLatest("steps/updateDataConfirm", updateStepConfirmSaga);
  yield takeLatest("steps/updateDataStart", updateStepStartSaga);
}

export default StepsSaga;
