import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { site } from "../../api/url_helper";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VerifyLink = () => {
  const navigate = useNavigate();
  let query = useQuery();
  const id = query.get("projectId");
  const guest_token = query.get("token");

  const [auth, setAuth] = React.useState<any>(null);

  useEffect(() => {
    const getAuth = async () => {
      const authData = await axios.post(
        `${site}/auth/verify-token`,
        { token: guest_token },
        { withCredentials: true }
      );
      setAuth(authData.data);
    };
    getAuth();
  }, []);

  //http://localhost:3000/verify-link?projectId=3a59c703-7152-4468-9677-178cbb411012&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJmYjRjNDRkNS0yM2YzLTRhOTgtOGQwZi0zNWFjZWVmOTYzZjAiLCJpYXQiOjE3MjM1NjEzNDksImV4cCI6MTcyMzgyMDU0OX0.OEF2tJSoQXj4uIoGaNAPBwAbSHkoTxKSyMK_WQLxY4I

  useEffect(() => {
    if (auth) {
      if (auth.auth === true) {
        navigate(`/dashboard?projectId=${id}`, { replace: true });
      } else {
        navigate("/login");
      }
    }
  }, [auth]);

  return (
    <div>
      <h1>VerifyLink</h1>
    </div>
  );
};

export default VerifyLink;
