import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WorkTaskState {
  workTaskData: any | null;
  error: string | null;
  loading: boolean;
  insertData: any | null;
  updateData: any | null;
  deleteData: any | null;
  errorInsert: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
}

const initialState: WorkTaskState = {
  workTaskData: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const WorkTaskSlice = createSlice({
  name: "work-task",
  initialState,
  reducers: {
    getWorkTaskAll: (
      state,
      action: PayloadAction<{ projectId: string; stepsId: string; query: any }>
    ) => {
      state.loading = true;
    },
    getWorkTaskAllSuccess: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.workTaskData = action.payload;
      state.error = null;
    },
    getWorkTaskAllFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.workTaskData = null;
    },
    addWorkTask: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addWorkTaskSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addWorkTaskFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateWorkTask: (
      state,
      action: PayloadAction<{ id: string; data: any }>
    ) => {
      state.loading = true;
    },
    updateWorkTaskSuccess: (state, action) => {
      state.loading = false;
      state.updateData = action.payload;
      state.error = null;
    },
    updateWorkTaskFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteWorkTask: (state) => {
      state.loading = true;
    },
    deleteWorkTaskSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteWorkTaskFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.loading = false;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
    },
  },
});

export const {
  getWorkTaskAll,
  getWorkTaskAllSuccess,
  getWorkTaskAllFail,
  addWorkTask,
  addWorkTaskSuccess,
  addWorkTaskFail,
  updateWorkTask,
  updateWorkTaskFail,
  updateWorkTaskSuccess,
  deleteWorkTask,
  deleteWorkTaskSuccess,
  deleteWorkTaskFail,
  setDataNull,
} = WorkTaskSlice.actions;

export default WorkTaskSlice.reducer;
