export const statusLevelItems = {
  NORMAL: "normal",
  NEED_ATTENTION: "need_attention",
  URGENT: "urgent",
};

export const statusConditionDate = {
  BEFORE: "before",
  AFTER: "after",
};

export const statusConditionMilestone = {
  ENTRY_DATE: "entry_date",
  RESIDENCE_STATUS_EXPIRATION_DATE: "residence_status_expiration_date",
  BIRTHDAY: "birthday",
  VISA_EXPIRATION_DATE: "visa_expiration_date",
  PASSED_ORDERS: "passed_orders",
};

export const ObjectType = {
  SYNDICATION: "syndication",
  DISPATCHING_COMPANY: "dispatching_company",
  RECEIVING_FACTORY: "receiving_factory",
  INTERN: "intern",
};
export const ViolateStatus = {
  NEW: "New",
  PROCESSING: "Processing",
  DONE: "Done",
};

export type TimelineDataProps = {
  date: string;
  title: string;
  color: string;
  progress: number;
  showProgress: number;
  complete: number; // đã hoàn thành
  type: string;
};

export type taskGroupsProps = {
  id: number;
  title: string;
  progress: string;
  selected: number;
};

export type itemsfunctionProps = {
  id: number;
  title: string;
  description: string;
  checked: boolean;
  avatar: string;
};

export const StepStatus = {
  // Ban đầu sẽ để mặc định
  COMPLETED: "complete",
  DOING: "doing", // đang làm
  PENDING: "pending",
};
